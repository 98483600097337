import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
import { Row, Col, Container, Button } from 'react-bootstrap';

const Register = ({ setAlert, register, isAuthenticated, accountActive }) => {
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    password: '',
    password2: '',
    tos: false,
    type: 'signup'
  });

  const navigate = useNavigate();

  const { username, email, password, password2, type, tos } = formData;

  const handleChange = (e) => {
      setFormData({...formData, [e.target.name]: e.target.value});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(
        username.includes('@') ||
        username.includes('.')
    ){
        setAlert('Your Callsign can not contain special characters', 'danger');
    } else if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else if(!username || !email){
      setAlert('Please fill out all fields', 'danger');
    } else if(!tos) {
      setAlert('You must first accept the Terms of Service and User Agreement', 'danger');
    } else {
      await register({ username, email, password, type, tos });
    }
  };

  const handleTos = e => {
    e.preventDefault();
    setFormData({...formData, tos: !tos})
  };

  if(isAuthenticated){
      navigate('/', {replace: true});
  }

  return (
    <Container className='mt-5 mb-3'>
      <Row>
        <Col>
            <h1>Sign Up</h1>
            <p>
                If you have a DMR or NXDN ID with RadioID.net, your account will be automatically verified.
            </p>
            <p>
                If you do not, your account will require manual review and you will need to upload a copy of your license.
            </p>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row className="mt-3">
          <Col>
              <div className="input-group shadow-sm">
                  <label className='input-group-text' htmlFor="username">Callsign:</label>
                  <input className="form-control" maxLength='8' id="username" name="username" onChange={handleChange} value={username} type="username" />
              </div>
          </Col>
          <Col>
              <div className="input-group shadow-sm">
                  <label className='input-group-text' htmlFor="email">Email:</label>
                  <input className="form-control" id="email" name="email" onChange={handleChange} value={email} type="email" />
              </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
              <div className="input-group shadow-sm">
                  <label className='input-group-text' htmlFor="password">Password:</label>
                  <input className="form-control" id="password" name="password" onChange={handleChange} type="password" />
              </div>
          </Col>
          <Col>
              <div className="input-group shadow-sm">
                  <label className='input-group-text' htmlFor="password2">Password:</label>
                  <input className="form-control" id="password2" name="password2" onChange={handleChange} type="password" />
              </div>
          </Col>
        </Row>
        {password !== password2 && password2.length > 0 && (
          <Row className="mt-1 d-flex justify-content-center">
              <span className="text-red password-mismatch">Passwords do not match</span>
          </Row>
        )}
        <Row className="mt-3 mb-1">
            <div className="col-auto input-group shadow-sm d-flex justify-content-center">
                <input
                  name="tos"
                  className="mb-2 text-white form-check-input"
                  id="toggle-check"
                  type="checkbox"
                  variant="outline-primary"
                  value={!tos}
                  checked={tos}
                  onInput={handleTos}
                />&nbsp;I agree to the&nbsp;<a href={'/tos'} rel="noreferrer" target='_blank' className='text-white'>Terms of Service and user Acknowledgement</a>
            </div>
        </Row>
        <Button className='btn-block' type='submit'>Create Account</Button>
      </form>
  </Container>
  );
};

Register.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    accountActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    alert: state.alert,
    isAuthenticated: state.auth.isAuthenticated,
    accountActive: state.auth.accountActive
});

export default connect(mapStateToProps, { setAlert, register })(Register);
