import React, {useState} from "react";
import {Container, Button, Col, Row} from "react-bootstrap";
import { passwordSubmit } from "../../actions/users";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {setAlert} from "../../actions/alert";


const Reset = ({ passwordSubmit, setAlert }) => {

    const navigate = useNavigate();

    const [ formData, setFormData ] = useState({
        password: "",
        password2: "",
        token: window.location.href.split('reset/')[1]
    });

    const handleChange = e => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async e => {
        e.preventDefault();
        passwordSubmit(formData);
        navigate('/signin', {replace: true});
    }

        const { password, password2 } = formData;
        return(
            <Container className='mt-5 belowNav w-50'>
                <h1>Password Reset</h1>
                <div>
                    <p>Forgot your password?</p>
                    <p>Choose a new one!</p>
                </div>
                <form className='form' onSubmit={handleSubmit}>
                    <Row className="mt-1">
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="password">Password:</label>
                                <input className="form-control" id="password" name="password" minLength={8} onChange={handleChange} type="password" required />
                            </div>
                        </Col>
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="password2">Password:</label>
                                <input className="form-control" id="password2" name="password2" minLength={8} onChange={handleChange} type="password" required />
                            </div>
                        </Col>
                    </Row>
                    {password !== password2 && password2.length > 0 && (
                        <Row className="mt-1 d-flex justify-content-center">
                            <span className="text-red password-mismatch">Passwords do not match</span>
                        </Row>
                    )}
                    <Button type='submit' className='btn btn-primary mt-3'>Request Reset</Button>
                </form>
            </Container>
        )
}

Reset.propTypes = {
    passwordSubmit: PropTypes.func.isRequired,
}

export default connect(null, { passwordSubmit, setAlert })(Reset);