import React, { useState } from "react";
import PhonebookEntry from './PhonebookEntry'
import { Table, Row, Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setAlert} from "../../actions/alert";

const Conference = ({auth: {user, isAdmin}, confPage, conferences, page, users}) => {

    return(
        <div className="belowNav">
            { confPage ? (
                <Row className="text-start" >
                    <Table className="overflow shadow" size='sm' responsive='sm' variant='dark' striped bordered hover>
                        <thead className='shadow'>
                            <tr>
                                <th>Extension</th>
                                <th>Description</th>
                                <th>Active Users</th>
                            </tr>
                        </thead>
                        <tbody>
                            { conferences.sort((a, b) => a.extension > b.extension ? 1 : -1).map(e => (
                                e.connectedUsers.length > 0 && (
                                    <PhonebookEntry
                                        key={e._id}
                                        dmrId={e.dmrId}
                                        username={e.callsign}
                                        firstName={e.name}
                                        city={e.city}
                                        state={e.state}
                                        country={e.country}
                                        extension={e}
                                        description={e.description}
                                        type={e.type}
                                        userCount={e.userCount}
                                        connectedUsers={e.connectedUsers}
                                        allstarNode={e.allstarNode}
                                        blf={e.status}
                                        talking={e.talking}
                                        currentUser={user}
                                        isAdmin={isAdmin}
                                        page={page}
                                        user={""}
                                    />
                                )
                            ))}
                            </tbody>
                        </Table>
                    </Row>
                ) : (
                    <Row className="text-start" >
                        <Table className="overflow shadow" size='sm' responsive='sm' variant='dark' striped bordered hover>
                            <thead className='shadow'>
                                <tr>
                                    <th>Extension</th>
                                    <th>Description</th>
                                    <th>Active Users</th>
                                </tr>
                            </thead>
                            <tbody>
                            { conferences.sort((a, b) => a.extension > b.extension ? 1 : -1).map(e => (
                                    <PhonebookEntry
                                        key={e._id}
                                        dmrId={e.dmrId}
                                        username={e.callsign}
                                        firstName={e.name}
                                        city={e.city}
                                        state={e.state}
                                        country={e.country}
                                        extension={e}
                                        description={e.description}
                                        type={e.type}
                                        userCount={e.userCount}
                                        connectedUsers={e.connectedUsers}
                                        allstarNode={e.allstarNode}
                                        blf={e.status}
                                        talking={e.talking}
                                        currentUser={user}
                                        isAdmin={isAdmin}
                                        page={page}
                                        user={""}
                                    />

                            ))}
                            </tbody>
                        </Table>
                    </Row>
                )
            }
        </div>
    )

}

Conference.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { setAlert })(Conference);