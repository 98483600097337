import React, { useState, useEffect } from "react";
import {Button, Card, Modal, Col, Container, Tooltip, OverlayTrigger} from "react-bootstrap";
import Moment from 'react-moment';
import InUse from '../../Images/inuse.png';
import Idle from '../../Images/idle.png';
import Ringing from '../../Images/ringing.gif';
import {io} from "socket.io-client";

export const DashboardTicket = ({type, status, date, tId, user, notes}) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false);

    if(show){
        return (
            <>
                <Col className="d-flex justify-content-center mt-3">
                    <Card className="extensionCards shadow" style={{ width: '20rem' }}>
                        <Card.Body>
                            <Card.Title className="text-black">{type} Request</Card.Title>
                            <hr />
                            <Card.Text>
                                <div className="mt-4">
                                    <div className='mt-1'>
                                        <span>Status: {status}</span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <span>Requested <Moment fromNow>{date}</Moment></span>
                                </div>
                                <div className='mt-2'>
                                    <Button variant='secondary' onClick={() => setShow(true)}>View/Add Notes</Button>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    size='lg'
                    className='d-flex justify-content-center shadow'
                >
                </Modal>
            </>
        );
    } else {
        return(
            <Col className="d-flex justify-content-center mt-3">
                <Card className="extensionCards shadow" style={{ width: '20rem' }}>
                    <Card.Body>
                        <Card.Title className="text-black">{type} Request</Card.Title>
                        <hr />
                        <Card.Text>
                            <div className="mt-4">
                                <div className='mt-1'>
                                    <span>Status: {status}</span>
                                </div>
                            </div>
                            <div className="mt-2">
                                <span>Requested <Moment fromNow>{date}</Moment></span>
                            </div>
                            <div className='mt-2'>
                                <Button variant='info' onClick={() => setShow(true)}>View/Add Notes</Button>
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}

export const DashboardExtension = ({type, extension, status}) => {

    return(
        <Container>
            <Col className="d-flex justify-content-center mt-3">
            <Card className="extensionCards shadow" style={{ width: '20rem' }}>
                <Card.Header>
                    <Card.Title className="text-black">{type}</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        <div>
                            <p>
                                <span>Extension: </span><span>{extension}</span>
                            </p>
                        </div>
                        <div>
                            {status === 'InUse' ? (
                                <img className='blf' src={InUse} alt="In Use" />
                            ) : status === 'Ringing' ? (
                                <img className='blf' src={Ringing} alt="Ringing" />
                            ) : (
                                <img className='blf' src={Idle} alt="Idle" />
                            )}
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>
        </Container>
    )
}

export const ActiveTicket = ({t, user, ENDPOINT}) => {

    const [unread, setUnread] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        t.notes.forEach(n => {
            if(user._id !== n.user._id && !n.read){
                setUnread(true);
            }
        })
    }, [t.notes, user.username, user]);

    const [note, setNotes] = useState({
        id: '',
        text: '',
        user: t.requestor,
    });

    const updateTicket = (data) => {
        let socket = io(ENDPOINT);
        socket.emit('ticket send', data);
    }

    const handleViewMessage = e => {
        e.preventDefault();
        setShow(true);
        setUnread(false);
        let { notes, _id, agent, requestor,  } = t
        notes = t.notes.map(n => {
            if(n.user !== user._id && !n.read){
                let {user, text, dateTime, read, _id} = n;
                read = true;
                n = {user, text, dateTime, read, _id};
                return n
            } else {
                return n
            }
        });
        t = {_id, agent, requestor, notes}
        updateTicket(t);
    } 

    const handleSetNote = e => {
        e.preventDefault();
        setNotes({
            id: t._id,
            text: e.target.value,
            user: user._id
        })
    }

    const handleSubmitNote = e => {
        e.preventDefault();
        let socket = io(ENDPOINT, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 3,
            transports: ['websocket']
        });
        socket.emit('note send', note);
        setNotes({text: ""});
    }

    return(
        <Col className='d-flex justify-content-center mt-3' key={t._id}>
            <Card className="extensionCards shadow" style={{ width: '20rem' }}>
                <Card.Header>
                    <Card.Title className="text-black">
                        <div className='position-relative'>
                            <span>
                                {(t.type && !t.type.includes('Inquiry')) && ("Requested")} {t.type}
                            </span>
                            {unread ? (
                                <span className="position-absolute end-0">
                                    <OverlayTrigger placement="bottom"
                                        overlay={
                                            <Tooltip id="button-tooltip-1">View unread messages</Tooltip>
                                        }
                                    >
                                        <Button variant="primary" className='noButton' onClick={handleViewMessage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red"
                                                 className="bi bi-envelope" viewBox="0 0 16 16">
                                                <path
                                                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                            </svg>
                                        </Button>
                                    </OverlayTrigger>
                                </span>
                            ) : (
                                <span className="position-absolute end-0">
                                    <OverlayTrigger placement="bottom"
                                                    overlay={
                                                        <Tooltip id="button-tooltip-1">View Messages</Tooltip>
                                                    }
                                    >
                                        <Button variant="primary" className='noButton' onClick={handleViewMessage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white"
                                                 className="bi bi-envelope" viewBox="0 0 16 16">
                                                <path
                                                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                            </svg>
                                        </Button>
                                    </OverlayTrigger>
                                </span>
                            )}
                        </div>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        <p>Ticket Number {t.ticketNumber}</p>
                        <p>Status:&nbsp;
                            <em>{t.status}</em>
                        </p>
                        <p>Last Updated:&nbsp;
                            <Moment
                                format="YYYY/MM/DD hh:mm:ss">
                                {t.last_modified}
                            </Moment>
                        </p>
                    </Card.Text>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose} centered className='text-black'>
                <Modal.Header closeButton>
                    <Modal.Title>Ticket {t.ticketNumber} Messages</Modal.Title>
                </Modal.Header>
                <Modal.Body className='noteCardBodyUser'>
                    {t.notes.length > 0 ? (
                        [...t.notes].reverse().map(n => (
                            <Card key={n._id} className='mb-2'>
                                <Card.Body className='d-flex justify-content-start'>
                                    <span className='text-start'>{n.text}</span>
                                </Card.Body>
                                <Card.Footer style={{height: '38px'}}>
                                    <div className='position-relative'>
                                        <span className='position-absolute start-0'>
                                            <span className='text-start'>{n.user === user._id ? (t.requestor.username) : ("Support Agent")}</span>
                                        </span>
                                        <span className='position-absolute end-0'>
                                            <span className='text-end'><Moment format="YYYY/MM/DD hh:mm:ss">{n.dateTime}</Moment></span>
                                        </span>
                                    </div>
                                </Card.Footer>
                            </Card>
                        ))
                    ) : (
                        <Card className='mb-2'>
                            <Card.Header>
                                No Notes
                            </Card.Header>
                            <Card.Body className='d-flex justify-content-start'>
                                <span className='text-start'>There are currently no messages on this ticket.</span>
                            </Card.Body>
                        </Card>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <form onSubmit={handleSubmitNote}>
                        <Col className="d-flex justify-content-start" style={{minWidth: '300px'}}>
                            <textarea className="form-control" cols="45" onChange={handleSetNote} value={note.text} name='text'></textarea>
                            <button type="submit" className="btn btn-sm btn-primary">Send</button>
                        </Col>
                    </form>
                </Modal.Footer>
            </Modal>
        </Col>
    )
}