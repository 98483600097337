import React, { Fragment } from 'react';
import {Routes, Route} from 'react-router-dom';
import Register from '../auth/Register';
import Login from '../auth/Login';
import Alert from '../layout/Alert';
import NotFound from '../layout/NotFound';
import Home from '../layout/Home';
import Dashboard from '../dashboard/Dashboard';
import Directory from '../phonebook/Directory';
import Tickets from '../tickets/Tickets';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserSettings from "../dashboard/UserSettings";
import Forgot from '../auth/Forgot';
import Reset from '../auth/Reset';
import Embed from "../layout/Embed";
import UserAdmin from "../auth/UserAdmin";

const Main = (auth) => {

  return (
    <Fragment>
        <Alert />
        <Routes>
            <Route exact path='/' element={
                <Home />
            } />
            <Route exact path='/signup' element={
                <Register />
            } />
            <Route exact path='/signin' element={
                <Login />
            } />
            <Route exact path='/forgot' element={
                <Forgot />
            } />
            <Route exact path='/reset/*' element={
                <Reset />
            } />
            <Route exact path='/tos' element={
                <Embed />
            } />
            <Route exact path='/wiki' element={
                <Embed />
            } />
            <Route exact path='/extblf' element={
                <Embed />
            } />
            <Route exact path='/confblf' element={
                <Embed />
            } />
            { (auth.auth.isAuthenticated && !auth.auth.loading) && (
                <Route exact path='/settings' element={
                    <UserSettings
                        PdmrId={auth.auth.dmrId}
                        PuserId={auth.auth.user._id}
                        Pusername={auth.auth.user.username}
                        PfirstName={auth.auth.user.firstName}
                        PlastName={auth.auth.user.lastName}
                        Pcity={auth.auth.user.city}
                        Pstate={auth.auth.user.state}
                        Pcountry={auth.auth.user.country}
                        Pemail={auth.auth.user.email}
                        PshareLocation={auth.auth.user.shareLocation}
                    />
                } />
            ) }
            <Route exact path='/phonebook' element={
                <Directory
                    auth={auth}
                />
            } />
            { (auth.auth.isAuthenticated ) && (
                <Route exact path='/dashboard' element={
                    <Dashboard />
                } />
            )}
            { auth.auth.isAdmin > 0 && (
                <Route exact path='/tickets' element={
                    <Tickets />
                } />
            )}
            { auth.auth.isAdmin > 0 && (
                <Route exact path='/useradmin' element={
                    <UserAdmin />
                } />
            )}
            <Route path="*" element={<NotFound />} />
        </Routes>
    </Fragment>
  );
};

Main.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, null)(Main);