import React, { useState } from "react";
import {Row, Col, Button} from "react-bootstrap";
import { connect } from "react-redux";
import {updateUser} from "../../actions/auth";
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";

const AdminSettings = ({ PdmrId, PuserId, Pusername, PfirstName, PlastName, Pcity, Pstate, Pcountry, Pemail, updateUser }) => {

    const navigate = useNavigate();

    const [ formData, setFormData ] = useState({
        dmrId: PdmrId,
        userId: PuserId,
        username: Pusername,
        firstName: PfirstName,
        lastName: PlastName,
        city: Pcity,
        state: Pstate,
        country: Pcountry,
        email: Pemail
    });
    
    const handleChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = e => {
        e.preventDefault();
        updateUser(formData);
        navigate('/dashboard', {replace: true});
    }

    const { dmrId, username, firstName, lastName, city, state, country, email } = formData;

        return(
            <div className="belowNav d-flex justify-content-center">
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <h4>
                                {username}
                            </h4>
                            <h4>
                                Account Settings
                            </h4>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mt-1">
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="username">Callsign: &nbsp;&nbsp;</label>
                                <input className="form-control" id="username" name="username" onChange={handleChange} value={username} type="username" />
                            </div>
                        </Col>
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="isAdmin">DMR ID: &nbsp;&nbsp;</label>
                                <input type="text" className="form-control" name="dmrId" value={dmrId} onChange={handleChange} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="firstName">First Name:</label>
                                <input className="form-control" id="firstName" name="firstName" onChange={handleChange} value={firstName} type="text" />
                            </div>
                        </Col>
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="lastName">Last Name:</label>
                                <input className="form-control" id="lastName" name="lastName" onChange={handleChange} value={lastName} type="text" />
                            </div>
                        </Col>
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="lastName">Email:</label>
                                <input className="form-control" id="lastName" name="email" onChange={handleChange} value={email} type="text" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <div className="col-auto input-group shadow-sm">
                                <label className='input-group-text' htmlFor="city">City:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input className="form-control" id="city" name="city" onChange={handleChange} value={city} type="text" />
                            </div>
                        </Col>
                        <Col>
                            <div className="col-auto input-group shadow-sm">
                                <label className='input-group-text' htmlFor="state">State:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input className="form-control" id="state" name="state" onChange={handleChange} value={state} type="text" />
                            </div>
                        </Col>
                        <Col>
                            <div className="col-auto input-group shadow-sm">
                                <label className='input-group-text' htmlFor="country">Country:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input className="form-control" id="country" name="country" onChange={handleChange} value={country} type="text" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Button type="submit" style={{width: '100%'}}>Update {username}</Button>
                        </Col>
                    </Row>
                </form>
            </div>
        );
}

AdminSettings.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    users: state.users
})

export default connect(mapStateToProps, { updateUser })(AdminSettings);