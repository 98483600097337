import React, {useEffect, useState, useReducer} from "react";
import { connect } from 'react-redux';
import UserEntry from './UserEntry'
import { Table, InputGroup, FormControl, Row, Col } from "react-bootstrap";
import AdminSettings from "./AdminSettings";
import PropTypes from 'prop-types';
import Spinner from "../layout/Spinner";
import {io} from "socket.io-client";

const UserAdmin = ({auth: {user, isAdmin}}) => {

    const ENDPOINT = process.env.REACT_APP_SOCKET_URI;

    const [loading, setLoading] = useState(true);

    const [{ users }, dispatch] = useReducer((state, action) => {
        if(action.type === 'LOAD_USERS') {
            state.users = action.data;
            return state
        }
        if(action.type === 'UPDATE_USER') {
            console.log(action.data._id) 
            const index = state.users.findIndex(user1 => user1._id === action.data._id);
            const newArray = [...state.users];
            newArray[index] = action.data;
            return {
                ...state,
                users: newArray
            }
        }
        return state
    }, { users: [] });

    if(loading){
        let socket = io(ENDPOINT, {transports: ["websocket"]});
        let verification = {id: user._id, jwt: localStorage.getItem('token')}
        socket.emit('user get', verification);
        socket.on("user set", (data) => {
            dispatch({ type: 'LOAD_USERS', data });
            setLoading(false);
        });
    }

    useEffect(() => {
        let socket = io(ENDPOINT, {transports: ["websocket"]});
        socket.on("get user update", (data) => {
            dispatch({ type: 'UPDATE_USER', data });
            setLoading(false);
        });
        return () => {
            socket.disconnect();
        }
    }, [ENDPOINT, dispatch]);

    const [ bookState, setBookState ] = useState({
        bookSearch: "",
        adminSettings: false,
        userId: "",
    });

    const { bookSearch, userId, adminSettings } = bookState;

    const handleSearch = e => {
        setBookState({[e.target.name]: e.target.value})
    };

    const handleAdminSettings = e => {
        setBookState({
            adminSettings: !adminSettings,
            userId: e
        });
    };

    if(!adminSettings){
        return(
            loading ? (
                <Spinner />
            ) : (
                <div className="belowNav">
                    <Row className="d-flex justify-content-end">
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Search</InputGroup.Text>
                                <FormControl name="bookSearch" id='bookSearch' value={bookSearch} onChange={handleSearch}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="text-start" >
                        <Table className="overflow shadow" size='sm' variant='dark' striped bordered hover>
                            <thead className='shadow'>
                                <tr>
                                    <th>Callsign</th>
                                    <th>Name</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Country</th>
                                    <th>DMR ID</th>
                                    <th>Account Verified</th>
                                    { isAdmin > 2 && (
                                        <>
                                            <th>Permissions</th>
                                            <th>User Admin</th>
                                        </>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                            { (bookSearch && bookSearch.length > 0) ? (
                                users.map(m => (
                                    ((m.username.toUpperCase().includes(bookSearch.toUpperCase())) ||
                                        (m.dmrId.toString().includes(bookSearch)) ||
                                        (m.firstName.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (m.lastName.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (m.city.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (m.state.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (m.country.toLowerCase().includes(bookSearch.toLowerCase())))
                                    && (
                                        <>
                                            <UserEntry
                                                key={m._id}
                                                dmrId={m.dmrId}
                                                username={m.username}
                                                firstName={m.firstName}
                                                lastName={m.lastName}
                                                city={m.city}
                                                state={m.state}
                                                country={m.country}
                                                currentUser={user}
                                                accountActive={m.accountActive}
                                                isAdmin={isAdmin}
                                                userAdminLevel={m.isAdmin}
                                                handleAdminSettings={handleAdminSettings.bind(this, m._id)}
                                            />
                                        </>

                                    )
                                ))
                            ) : (
                                !loading && (
                                    users.map(m => (
                                        <UserEntry
                                            key={m._id}
                                            dmrId={m.dmrId}
                                            username={m.username}
                                            firstName={m.firstName}
                                            lastName={m.lastName}
                                            city={m.city}
                                            state={m.state}
                                            country={m.country}
                                            currentUser={user}
                                            accountActive={m.accountActive}
                                            isAdmin={isAdmin}
                                            userAdminLevel={m.isAdmin}
                                            handleAdminSettings={handleAdminSettings.bind(this, m._id)}
                                        />
                                    ))
                                )
                            )}
                            </tbody>
                        </Table>
                    </Row>
                </div>
            )
        )
    } else if(adminSettings && isAdmin > 2){
        return (
            loading ? (
                <Spinner />
            ) : (
                users.map((m) => (
                    m._id === userId && (
                        <AdminSettings
                            key={m._id}
                            user={m}
                            setBookState={setBookState}
                        />
                    )
                ))
            )
        )
    }

}

UserAdmin.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, null)(UserAdmin);