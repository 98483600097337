import React from "react";
import PhonebookEntry from './PhonebookEntry'
import { Table, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setAlert} from "../../actions/alert";

const BLF = ({auth: {user, isAdmin}, extensions, page, users}) => {

    return(
            <div className="belowNav">
                <Row className="text-start" >
                    <Table className="overflow shadow" responsive='sm' size='sm' variant='dark' striped bordered hover>
                        <thead className='shadow'>
                            <tr>
                                <th>
                                    Callsign
                                </th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Extension
                                </th>
                                <th>
                                    Type
                                </th>
                                <th>
                                    HOIP Server
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        { extensions.map(e => (
                                ((e.type === 'Extension' && e.status === "InUse") ||
                                    (e.type === 'Trunk' && e.status === "InUse")) && (
                                    <PhonebookEntry
                                        key={e._id}
                                        dmrId={e.dmrId}
                                        username={e.callsign}
                                        firstName={e.name}
                                        city={e.city}
                                        state={e.state}
                                        country={e.country}
                                        extension={e}
                                        description={e.description}
                                        type={e.type}
                                        userCount={e.userCount}
                                        connectedUsers={e.connectedUsers}
                                        allstarNode={e.allstarNode}
                                        blf={e.status}
                                        talking={e.talking}
                                        currentUser={user}
                                        isAdmin={isAdmin}
                                        page={page}
                                        user={users.filter(user => user.username.toLowerCase() === e.callsign.toLowerCase())}
                                    />
                                )
                            ))
                        }
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    <p className='text-end'>* Experimental</p>
                </Row>
            </div>
        )

}

BLF.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { setAlert })(BLF);