import React, { useState, useEffect } from "react";
import {Button, Card, Modal, Col, Container, Tooltip, OverlayTrigger} from "react-bootstrap";

export const RfLinkPopup = ({disclaimer, setDisclaimer, setRequestNew}) => {
    return (
        <>
            <Modal show={disclaimer.show}>
                <Modal.Header className="text-black" closeButton>
                    <Modal.Title>
                        {disclaimer.type === "RF Link" && (
                            "Allstar Link Alert"
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-black">
                    {disclaimer.type === "RF Link" && (
                        <>
                            <p>Before proceeding to submit this ticket request for an ALLSTAR-LINK, <em>YOU MUST</em>:</p> 
                            <p>TEST YOUR CONNECTION FROM AN EXTERNAL IP NETWORK AND ENSURE THAT YOUR ALLSTAR-LINK IS WORKING with an IAX CLIENT.</p>
                            <p>PLEASE DO NOT USE IP ADDRESS as part of your IAX2-STRING. YOU MUST USE AN FQDN as IP Addresses can change at any time.</p>
                            <p>Please <a href="https://hamsoverip.github.io/wiki/other/configure-rf-links/" target="_blank" rel="noreferrer">click here</a> for the Allstar-Link guide.</p>
                            <p>Please submit all information pertaining to your Allstar-Link Request.  Incomplete information will delay the process of your request. </p>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={e => setRequestNew(false)}>
                        Cancel Ticket
                    </Button>
                    <Button variant="primary" onClick={e => setDisclaimer(!disclaimer)}>Continue</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export const newTicketPopup = ({newTicketAlert, setNewTicketAlert}) => {
    return (
        <>
            <Modal show={newTicketAlert}>
                <Modal.Header className="text-black" closeButton>
                    <Modal.Title>Ticket Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-black">
                    <p>
                        Your ticket has been submitted. You will receive a confirmation email. When your ticket has been closed, you 
                        will receive another email with your details. Check your dashboard periodically. If you have an open ticket with 
                        a red envelope, you have unread messages on your ticket.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={e => setNewTicketAlert(!newTicketAlert)}>Continue</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}