import React, { useEffect, useState, useReducer } from "react";
import { Table } from 'react-bootstrap';
import { connect } from "react-redux";
import Moment from "react-moment";
import PropTypes from "prop-types";
import TicketNav from "./TicketNav";
import WorkTicket from "./WorkTicket";
import {io} from "socket.io-client";
import Spinner from "../layout/Spinner";

const Tickets = ({ auth: { user, loading }}) => {

    const ENDPOINT = process.env.REACT_APP_SOCKET_URI;

    const [socketLoad, setSocketLoad] = useState(true);

    const [users, setUsers] = useState([]);

    const [{ tickets }, dispatch] = useReducer((state, action) => {
        if (action.type === 'LOAD_TICKETS') {
            state.tickets = action.data;
            return state
        }
        if(action.type === 'UPDATE_TICKET'){
            const index = state.tickets.findIndex(ticket => ticket.ticketNumber === action.data.ticket.ticketNumber);
            const newArray = [...state.tickets];
            newArray[index] = action.data.ticket;
            return {
                ...state,
                tickets: newArray
            }
        }
        if(action.type === 'NEW_TICKET'){
            return {
                ...state,
                tickets: [...state.tickets.filter(ticket => ticket.ticketNumber !== action.data.ticket.ticketNumber), action.data.ticket]
            }
        }
        return state
    }, { tickets: [] });

    const handleFirstLoad = () => {
        let socket = io(ENDPOINT, {transports: ["websocket"]});
        let verification = {id: user._id, jwt: localStorage.getItem('token')}
        socket.emit('ticket get', verification);
        socket.on('ticket set', async function (data) {
            dispatch({ type: 'LOAD_TICKETS', data });
        });
        socket.emit('user get', verification);
        socket.on('user set', async function (data) {
            setUsers(data);
            setSocketLoad(false);
        });
    }
    if(socketLoad) {
        handleFirstLoad();
    }

    const [sendingNote, setSendingNote] = useState(false);

    const updateTicket = (data) => {
        if(data.accountActive){
            data = {
                accountActive: data.accountActive,
                _id: data._id,
                requestorId: data.requestor._id,
                status: data.status,
                agent: user._id
            }
        } else {
            data = {
                accountActive: data.accountActive,
                _id: data._id,
                status: data.status,
                agent: data.agent,
                region: data.region,
                type: data.type,
                lat: data.lat,
                lon: data.lon,
                notes: data.notes,
                conferenceType: data.conferenceType,
                bridgeName: data.bridgeName,
                bridgePin: data.bridgeName,
                allstar: data.allstar,
                text: data.text,
                extension: data.extension,
                password: data.password,
            }
        }
        let socket = io(ENDPOINT, {transports: ["websocket"]});
        socket.emit('ticket send', data);
    }

    
    useEffect(() => {
            let socket = io(ENDPOINT, {transports: ["websocket"]});
            socket.on("send ticket", (data) => {
                if(data.type === 'new'){
                    dispatch({ type: 'NEW_TICKET', data });
                } if(data.type === 'update'){
                    dispatch({ type: 'UPDATE_TICKET', data });
                    setSendingNote(false);
                }
            });
            return () => {
                socket.disconnect();
            }
    }, [ENDPOINT, dispatch, setSendingNote]);

    const [page, setPage] = useState("Submitted");

    const handleSetPage = e => {
        e.preventDefault();
        setPage(e.target.name);
    }

    const [workTicket, setWorkTicket] = useState({
        open: false,
        ticket: {},
        agent: user._id,
    });

    const handleTicket = (e, t) => {
        e.preventDefault();
        updateTicket(t);
    }

    const handleWorkTicket = (e, t) => {
        let updTick = {...t};
        updTick.status = "In Progress";
        updTick.agent = user._id;
        setWorkTicket({
            open: !workTicket.open,
            ticket: updTick,
            agent: user
        });
    }

    const handleReload = e => {
        e.preventDefault();
        setSocketLoad(true);
    }

    const checkForUnread = (notes) => {
        let read = false;
        console.log(notes);
        notes.map(n => {
            if(!n.read && n.user !== user._id){
                read = true;
            }
        });
        return read;
    }

    return (
        !socketLoad ? (
            <div>
                {!workTicket.open ? (
                    <div className="belowNav">
                        <TicketNav
                            handleSetPage={handleSetPage}
                            page={page}
                        />
                        <Table size="sm" responsive="sm" className="table table-dark table-striped text-start container-fluid">
                            <thead>
                            <tr>
                                <th scope="col">Ticket #</th>
                                <th scope="col">Requestor</th>
                                <th scope="col">Request Type</th>
                                <th scope="col">Request Date</th>
                                {page !== "Submitted" && (
                                    <>
                                        <th scope="col">Last Modified</th>
                                        <th scope="col">Assign</th>
                                        <th scope="col">Messages</th>
                                    </>
                                )}
                                <th scope="col" className="position-relative">
                                    <span>Handle Ticket</span>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" onClick={handleReload}
                                             fill="currentColor" className="bi bi-arrow-clockwise position-absolute end-0" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                        </svg>
                                    </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {tickets.map(t => (
                                    page === "Submitted" ? (
                                        t.status === "Submitted" && (
                                            <tr key={t._id}>
                                                <td>{t.ticketNumber}</td>
                                                <th>{t.requestor.username} {t.requestor.firstName}</th>
                                                <td>{t.type}</td>
                                                <td><Moment format="YYYY/MM/DD hh:mm:ss">
                                                    {t.created_date}
                                                </Moment></td>
                                                <td><button onClick={e => handleWorkTicket(e, t)} className="btn btn-sm btn-primary">Work Ticket</button></td>
                                            </tr>
                                        )
                                    ) : page === "My" ? (
                                        (t.status === "In Progress" && t.agent._id === user._id) && (
                                            <tr key={t._id}>
                                                <td>{t.ticketNumber}</td>
                                                <th>{t.requestor.username} {t.requestor.firstName}</th>
                                                <td>{t.type}</td>
                                                <td><Moment format="YYYY/MM/DD hh:mm:ss">
                                                    {t.created_date}
                                                </Moment></td>
                                                <td>
                                                    <Moment format="YYYY/MM/DD hh:mm:ss">
                                                        {t.last_modified ? (
                                                            t.last_modified
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </Moment>
                                                </td>
                                                <td>{t.agent && (t.agent.username)}</td>
                                                <td>
                                                    {checkForUnread(t.notes) === true ? ('Un-Read') : ("Read")}
                                                </td>
                                                <td><button onClick={e => handleWorkTicket(e, t)} className="btn btn-sm btn-primary">Handle Ticket</button></td>
                                            </tr>
                                        )
                                    ) : page === "In Progress" ? (
                                        t.status === "In Progress" && (
                                            <tr key={t._id}>
                                                <td>{t.ticketNumber}</td>
                                                <th>{t.requestor.username} {t.requestor.firstName}</th>
                                                <td>{t.type}</td>
                                                <td><Moment format="YYYY/MM/DD hh:mm:ss">
                                                    {t.created_date}
                                                </Moment></td>
                                                <td>
                                                    <Moment format="YYYY/MM/DD hh:mm:ss">
                                                        {t.last_modified ? (
                                                            t.last_modified
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </Moment>
                                                </td>
                                                <td>{t.agent && (t.agent.username)}</td>
                                                <td>
                                                    
                                                </td>
                                                <td><button onClick={e => handleWorkTicket(e, t)} className="btn btn-sm btn-primary">Handle Ticket</button></td>
                                            </tr>
                                        )
                                    ) : page === 'Closed' && (
                                        (t.status !== 'In Progress' && t.status !== 'Submitted') && (
                                            <tr key={t._id}>
                                                <td>{t.ticketNumber}</td>
                                                <th>{t.requestor.username} {t.requestor.firstName}</th>
                                                <td>{t.type}</td>
                                                <td><Moment format="YYYY/MM/DD hh:mm:ss">
                                                    {t.created_date}
                                                </Moment></td>
                                                <td>
                                                    <Moment format="YYYY/MM/DD hh:mm:ss">
                                                        {t.last_modified ? (
                                                            t.last_modified
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </Moment>
                                                </td>
                                                <td>{t.agent && (t.agent.username)}</td>
                                                <td>
                                                    
                                                </td>
                                                <td><button onClick={e => handleWorkTicket(e, t)} className="btn btn-sm btn-primary">Handle Ticket</button></td>
                                            </tr>
                                        )
                                    )
                                ))}
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <WorkTicket
                        workTicket={workTicket}
                        setWorkTicket={setWorkTicket}
                        users={users}
                        tickets={tickets}
                        handleTicket={handleTicket}
                        dispatch={dispatch}
                        updateTicket={updateTicket}
                        sendingNote={sendingNote}
                        setSendingNote={setSendingNote}
                    />
                )}
            </div>
        ) : (
            <div className='belowNav'>
                <Spinner />
            </div>
        )
    )
}


Tickets.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, null)(Tickets)