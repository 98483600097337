import React from "react";
import { Col, Container, Image, NavLink, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Phone from '../../Images/phone.png';
import Money from '../../Images/money.png';
import Discord from '../../Images/discord.png';
import PropTypes from "prop-types";

const Home = ({isAuthenticated}) => {

    let iconOneRoute = "signup";
    let dashboardTextTitle = "Submit a Ticket";
    let dashboardDescription = "Request a service, extension or ask for help.";
    let moneyTitle = "Donate to the project";
    let moneyText = "Hams Over IP is a 501c3 and depends on donations from it's users to operate. If you enjoy this service, please consider making a donation " + 
    "to help keep us running!";
    let discordTitle = "Join our Discord";
    let discordText = "If you need assistance or would like to have more discussion about a topic, join our discord community!";
    if(isAuthenticated){
        iconOneRoute = "dashboard";
        dashboardTextTitle = "Go to Dashboard";
        dashboardDescription = "You are signed in! Go to your Dashboard to see your extensions and active requests, or make a new request!";
    }
    
        return(
            <Container fluid className="">
                <Row className="pb-3 d-flex justify-content-center intro-text head-stripe">
                    <Col className="text-end mt-3 border-right">
                        <h1 className="">HOIP</h1>
                        <p className="">Ham Radio Operators from all over the world have volunteered their time, skills, and resources to create a
                            new and dynamic Ham Radio voice over IP service for our ham radio community.
                        </p>
                    </Col>
                    <Col className="text-start mt-3 border-left">
                        <h1 className="">FEATURES</h1>
                        <div>Fully featured, searchable, BLF integrated phonebook</div>
                        <div>Up to TEN endpoints per extension</div>
                        <div>IAX Trunks, SIP Trunks</div>
                        <div className=''>Conference Bridges for Individuals, Clubs and Groups</div>
                    </Col>
                </Row>
                <NavLink href={`https://helpdesk.hamsoverip.com/osticket/`} className="link-text link-visited link-hover">
                    <Row className="mt-5">
                        <Col className="d-flex justify-content-center">
                                <Image className="PhoneImg" src={Phone} fluid />
                        </Col>
                        <Col className="text-start mt-3">
                            <h2>{dashboardTextTitle}</h2>
                            <p>{dashboardDescription}</p>
                        </Col>
                    </Row>
                </NavLink>
                <NavLink target="_blank" href="https://www.gofundme.com/f/calling-cq-cq?utm_campaign=p_cp+share-sheet&utm_medium=copy_link_all&utm_source=customer" className="link-text link-visited link-hover">
                    <Row className="mt-5">
                        <Col className="d-flex justify-content-center">
                            <Image className="moneyImg" src={Money} fluid />
                        </Col>
                        <Col className="text-start mt-4" xs="12" md="6">
                            <h2>{moneyTitle}</h2>
                            <p>{moneyText}</p>
                        </Col>
                    </Row>
                </NavLink>
                <NavLink target="_blank" href="https://discord.gg/hEJqeV7W9Q" className="link-text link-visited link-hover">
                    <Row className="mt-5">
                        <Col className="d-flex justify-content-center">
                                <Image className="discordImg" src={Discord} fluid />
                        </Col>
                        <Col className="text-start mt-5" xs="12" md="6">
                            <h2>{discordTitle}</h2>
                            <p>{discordText}</p>
                        </Col>
                    </Row>
                </NavLink>
                {/* <div className="mt-5">
                    <Row className="pb-3 intro-text head-stripe">
                        <Col className="text-end mt-3 border-right">
                            <h1 className="">Some Content</h1>
                            <p className="">
                                Content would be great here!
                            </p>
                        </Col>
                        <Col className="text-start mt-3 border-left">
                            <h1 className="">More Content</h1>
                            <p>
                                Here too! Give me ideas! :)
                            </p>
                        </Col>
                    </Row>
                </div> */}
            </Container>
        )
}

Home.propTypes = {
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Home);