import api from '../utils/api';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  LOGIN_SUCCESS,
  UPDATE_USER,
  LOGOUT
} from './types';

// Load User
export const loadUser = () => async dispatch => {
  try {
    const res = await api.get('/auth');
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    if(!err.message.includes("data")){
      dispatch(setAlert(err.message, 'danger'));
    }
  }
};

// Register User
export const register = formData => async dispatch => {
  const { type } = formData;
  try {
    const res = await api.post(`/auth/${type}`, formData);
    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch(setAlert(err.response.data.error.message, 'danger'));
    dispatch({
      type: REGISTER_FAIL
    });
  }
};

// Login User
export const login = (username, password, type) => async dispatch => {
  const body = { username, password };
  try {
    const res = await api.post(`/auth/${type}`, body);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });
    dispatch(loadUser());
  } catch (err) {
    if(password === ''){
      err.message = "Incorrect Password";
    } else if(err.message.includes("data")){
      err.message = "Network Error";
    }
    dispatch(setAlert(err.message, 'danger'));
  }
};

export const updateUser = (payload) => async dispatch => {
  try {
    const res = await api.post(`/auth/update`, payload);
    dispatch({
      type: UPDATE_USER,
      payload: res.data
    })       
  } catch (err) {
    dispatch(setAlert(err.response.data.error.message, 'danger'));
  }
}

// Logout
export const logout = () => async dispatch => {
  try {
    dispatch({
      type: LOGOUT
    });
  } catch (err) {
    dispatch(setAlert(err.response.data.error.message, 'danger'));
  }
}
