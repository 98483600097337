import React, {Fragment, useState} from 'react';
import unsure from '../../Images/unsure.gif';
import {Row} from "react-bootstrap";
import {connect} from "react-redux";
import {setAlert} from "../../actions/alert";

const NotFound = (auth) => {
    const [time, setTime] = useState(true);

    setTimeout(() => {
        setTime(false);
    }, 5000);

    if(time) {
        return (
            <Row className="belowNav d-flex justify-content-center align-items-center pt-5 mt-5 mb-5 pb-5">
                <div className="spinner-grow text-primary mb-5 mt-5" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </Row>
        )
    } else if (
        (window.location.href.includes('/phonebook') && !auth.isAuthenticated) ||
        (window.location.href.includes('/phonebook') && !auth.accountActive) ||
        (window.location.href.includes('/dashboard') && !auth.isAuthenticated) ||
        (window.location.href.includes('/dashboard') && !auth.accountActive) ||
        (window.location.href.includes('/settings') && !auth.isAuthenticated) ||
        (window.location.href.includes('/settings') && !auth.accountActive)
    ){
        return (
            <Fragment>
                <h1 className='belowNav mt-5'>
                    You don't have permission to access this!
                </h1>
                <div>
                    <img className='mt-2' src={unsure} alt={'Thinking Man'} />
                </div>
                {
                    !auth.isAuthenticated && (
                        <p className='mt-5'>Please <a href='/signin'>Sign In</a> to access the Phonebook!</p>
                    )
                }
                {
                    (auth.isAuthenticated && !auth.accountActive) && (
                        <p className='mt-5'>We are still verifying your account and apologize for the inconvenience.</p>
                    )
                }
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <h1 className='belowNav mt-5'>
                    We can't find what you're looking for...
                </h1>
                <div>
                    <img className='mt-2' alt={'Thinking Man'} src={unsure} />
                </div>
                <p className='mt-5'>If you believe this is a mistake, please drop into our <a target="_blank" rel='noreferrer' href="https://discord.com/invite/hEJqeV7W9Q">Discord</a> and let us know.</p>
            </Fragment>
        );
    }
};

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, {setAlert})(NotFound);
