import React from "react";
import PhonebookEntry from './PhonebookEntry'
import { Table, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setAlert} from "../../actions/alert";

const RFLink = ({auth: { user, isAdmin}, RFLinks, page, users}) => {

    return(
        <div className="belowNav">
            <Row className="text-start" >
                <Table className="overflow shadow" size='sm' responsive='sm' variant='dark' striped bordered hover>
                    <thead className='shadow'>
                    <tr>
                        <th>Owner Callsign</th>
                        <th>Extension</th>
                        <th>Link Name</th>
                        <th>Allstar Node</th>
                    </tr>
                    </thead>
                    <tbody>
                    { RFLinks.sort((a,b) => a.extension - b.extension).map(e => (
                            <PhonebookEntry
                                key={e._id}
                                dmrId={e.dmrId}
                                username={e.callsign}
                                firstName={e.name}
                                city={e.city}
                                state={e.state}
                                country={e.country}
                                extension={e}
                                description={e.description}
                                type={e.type}
                                userCount={e.userCount}
                                connectedUsers={e.connectedUsers}
                                allstarNode={e.allstarNode}
                                blf={e.status}
                                talking={e.talking}
                                currentUser={user}
                                isAdmin={isAdmin}
                                page={page}
                                user={users.filter(user => user.username.toLowerCase() === e.callsign.toLowerCase())}
                            />
                        )
                    )}
                    </tbody>
                </Table>
            </Row>
            <Row>
                <p className='text-end'>* Experimental</p>
            </Row>
        </div>
    )

}

RFLink.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { setAlert })(RFLink);