import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { Container, Row } from 'react-bootstrap';
import { setAlert } from "../../actions/alert";

const Login = ({ login, isAuthenticated, loading, setAlert }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    type: 'signin'
  });

  const [ submitClicked, setSubmitClicked ] = useState(false);

  const { username, password, type } = formData;

  const handleChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setSubmitClicked(!submitClicked);
    login(username, password, type);
  };

  if(!loading && isAuthenticated){
      window.location = "/dashboard";
  }


  return (
    <Container className='w-50 mt-5'>
      <h1 className="large">Sign In</h1>
      <p className="lead mt-3">
        <i className="fas fa-user" />Sign in to Your Account
      </p>
      <form className="form" onSubmit={onSubmit}>
          <Row>
            <div className="input-group shadow-sm mt-1">
                <label className='input-group-text' htmlFor="username">Callsign: &nbsp;&nbsp;</label>
                <input className="form-control" id="username" name="username" onChange={handleChange} value={username} type="username" />
            </div>
          </Row>
          <Row>
            <div className="input-group shadow-sm mt-1">
                <label className='input-group-text' htmlFor="password">Password:</label>
                <input className="form-control" id="password" name="password" onChange={handleChange} type="password" />
            </div>
          </Row>
            <input type="submit" className="btn btn-primary mt-1 btn-block" value="Login" />
      </form>
        <p className="mt-4"><Link className='footerLink' to="/signup">Create Account</Link></p>
        <p className="mt-3"><Link className='footerLink' to="/forgot">Forgot Password</Link></p>
    </Container>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login, setAlert })(Login);
