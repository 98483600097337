import React, { useEffect, useState } from "react";
import {Row, Col, Form, InputGroup, Nav} from "react-bootstrap";

const PhonebookNav = ({confPage, setConfPage, handleList, page, user, handleSearch, search}) => {

    const handleConf = () => {
        setConfPage(!confPage)
    }

    return(
        <Row>
            <Col className='PbNavCol d-flex justify-content-start'>
                <Nav variant="tabs" className='noBottomBorder'>
                  <Nav.Item>
                    <Nav.Link
                        onClick={handleList}
                        active={page === 'Extension'}
                        style={page !== 'Extension' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                        name="Extension"
                    >
                        Extensions
                    </Nav.Link>
                  </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            onClick={handleList}
                            active={page === 'BLF'}
                            name="BLF"
                            style={page !== 'BLF' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                        >Active BLF
                        </Nav.Link>
                    </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                        onClick={handleList}
                        active={page === 'Conference'}
                        name="Conference"
                        style={page !== 'Conference' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                    >
                        Conferences
                    </Nav.Link>
                  </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            onClick={handleList}
                            active={page === 'RFLink'}
                            name="RFLink"
                            style={page !== 'RFLink' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                        >
                            Allstar Link
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            onClick={handleList}
                            active={page === 'Audio'}
                            name="Audio"
                            style={page !== 'Audio' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                        >
                            Audio
                        </Nav.Link>
                    </Nav.Item>
                    {/* { user.isAdmin > 0 && (
                        <Nav.Item>
                            <Nav.Link
                                onClick={handleList}
                                active={page === 'Admin'}
                                name="Admin"
                                style={page !== 'Admin' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                            >
                                Admin
                            </Nav.Link>
                        </Nav.Item>
                    )} */}
                </Nav>
            </Col>
            {page === 'Extension' && (
                <Col className="d-flex justify-content-end position-relative">
                    <InputGroup className="bookSearch">
                        <Form.Control
                            type="text"
                            name="bookSearch"
                            id='bookSearch'
                            style={{height: 'min-content'}}
                            placeholder="Search"
                            value={search}
                            onChange={handleSearch}
                        />
                    </InputGroup>
                </Col>
            )}
            {page === "Conference" && (
                <Col className="d-flex justify-content-end position-relative">
                    <Form.Check 
                        type="switch"
                        id="custom-switch"
                        label="Full List"
                        onClick={handleConf}
                    />
                </Col>
            )}
        </Row>
    )
}

export default PhonebookNav;