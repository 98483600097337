import {Container} from "react-bootstrap";
import React from "react";
import UserAck from '../../Images/HOIP_User_Acknowledgement.pdf';
import TOS from '../../Images/HOIP_TermsOfService.pdf';

const Embed = () => {
    return(
        window.location.href.includes('/tos') ? (
            <Container className="belowNav">
                <div>
                    <object data={TOS} aria-label="TOS" width="100%" style={{height: '90vh'}}></object>
                </div>
                <br/>
                <div>
                    <object data={UserAck} aria-label="User Acknowledgement" width="100%" style={{height: '90vh'}}></object>
                </div>
            </Container>
        ) : window.location.href.includes('/wiki') ? (
            <div>
                <object className='static-top' aria-label="Wiki" data={'https://hamsoverip.github.io/wiki'} width="100%" style={{height: '90vh'}}></object>
            </div>
        ) : window.location.href.includes('/extblf') ? (
            <div className='belowNav'>
                <object className='static-top' aria-label="BLF" data={'https://blf.hamsoverip.com/blf/'} width="100%" style={{height: '90vh'}}></object>
            </div>
        ) : window.location.href.includes('/confblf') && (
            <div className='belowNav'>
                <object className='static-top' aria-label="BLF Bridges" data={'https://blf.hamsoverip.com/blf/monitor-bridgecalls.php'} width="100%" style={{height: '90vh'}}></object>
            </div>
        )
    )
}

export default Embed;