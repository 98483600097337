import React, {Fragment} from "react";
import { Container, Navbar, Nav, Image, NavDropdown } from 'react-bootstrap';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Banner from '../../Images/banner.webp';
import cartoonPhone from '../../Images/cartoonPhone.png';
import PropTypes from "prop-types";
import { logout } from '../../actions/auth';

const NavCont = ({ auth: { isAuthenticated, user, loading }, logout }) => {

  const navigate = useNavigate();
  
    const handleCollapse = () => {
      let nav = document.getElementById("collapsible-navbar-nav");
      let btn = document.getElementById("navbarBtn");
      nav.classList.remove("show");
      btn.classList.add("collapsed");
    };

    const handleNav = e => {
      navigate(e.target.name, {replace: true})
      handleCollapse()
    }

    const handleLogout = () => {
      logout();
      navigate('/', {replace: true});
    }

        return (
          <>
            <img src={Banner} alt={'Hams Over IP'} className="img-fluid" />
            <div className="navBar sticky-top">
              <Navbar expand="lg" bg="dark" variant="dark" className="navbar-dark bg-dark">
                <Container>
                  <Navbar.Brand target="/" onClick={handleNav}>
                    <Image  src={cartoonPhone} fluid className="navbarImg"/>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" id="navbarBtn" />
                  <Navbar.Collapse id="collapsible-navbar-nav" variant="dark" bg="dark" className="justify-content-end">
                    <Nav className="justify-content-end">
                      <Nav.Link value="/" onClick={handleNav}>Home</Nav.Link>
                      { !loading && (
                        <>
                          <Nav.Link name="/phonebook" onClick={handleNav}>Phonebook</Nav.Link>
                          <Nav.Link name="/wiki" onClick={handleNav}>Wiki</Nav.Link>
                        </>
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </>
        );
}

NavCont.propTypes = {
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  accountActive: PropTypes.bool,
}

const mapStateToProps = state => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
  accountActive: state.auth.accountActive,
  user: state.auth.user
})

export default connect(mapStateToProps, {logout})(NavCont);