import React from 'react';
import {Row, Container} from 'react-bootstrap';

const Spinner = () => (
    <Container style={{marginTop: '25vh'}} className="pb-5 mb-5">
        <Row className="pt-3 pb-5 mb-5">
            {/* <div>
                <p className='animate-spinner-h color-change'>H</p>
            </div>
            <div>
                <p className='animate-spinner-hoip color-change'>HOIP</p>
            </div> */}
            <div>
                {(window.location.href.includes("ticket")) ? (
                    <h4 className='animate-spinner color-change mb-5 pb-5'>Tickets Loading...</h4>
                ) : window.location.href.includes("useradmin") ? (
                    <h4 className='animate-spinner color-change mb-5 pb-5'>User Admin Loading...</h4>
                ) : window.location.href.includes("phonebook") ? (
                    <h4 className='animate-spinner color-change mb-5 pb-5'>Phonebook Loading...</h4>
                ) : window.location.href.includes("dashboard") && (
                    <h4 className='animate-spinner color-change mb-5 pb-5'>Dashboard Loading...</h4>
                )}
            </div>
        </Row>
    </Container>
);

export default Spinner;
