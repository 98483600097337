import {Buffer} from "buffer";
import React, { useState } from "react";
import {Card, Col, Container, Form, Row, FloatingLabel, Alert} from "react-bootstrap";
import {io} from "socket.io-client";
import Moment from "react-moment";


const WorkTicket = ({workTicket: {ticket, agent}, users, tickets, setWorkTicket, handleTicket, updateTicket, setSendingNote, sendingNote}) => {

    const ENDPOINT = process.env.REACT_APP_SOCKET_URI;
    const [ newTicket, setT ] = useState(ticket);

    const handleChange = e => {
        e.preventDefault();
        setT({...newTicket, [e.target.name]: e.target.value});
    }

    const submitAccountUpdate = e => {
        e.preventDefault();
        handleTicket(e, newTicket);
        updateTicket(newTicket);
        setWorkTicket({open: false});
    }

    const [note, setNotes] = useState({
        id: '',
        text: '',
        user: agent._id,
    });

    const handleSetNote = e => {
        e.preventDefault();
        setNotes({
            id: newTicket._id,
            text: e.target.value,
            user: agent._id
        })
    }

    const handleSubmitNote = e => {
        e.preventDefault();
        setSendingNote(true);
        let socket = io(ENDPOINT, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 3,
            transports: ['websocket']
        });
        socket.emit('note send', note);
        setNotes({text: ""});
    }

    return(
        <Container fluid className="d-flex justify-content-center mt-3 mb-5">
            <Row style={{height: "max-content"}}>
                <Col className="mt-3" style={{minWidth: 'max-content'}}>
                    <Card style={{maxWidth: 'max-content'}}>
                        <Card.Header>
                            <div className="position-relative">
                                <span className="text-center text-black">{newTicket.requestor.username} {newTicket.type}</span>
                                <span className="position-absolute end-0" onClick={e => setWorkTicket({open: false})}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </span>
                            </div>
                        </Card.Header>
                        <Card.Body className="d-flex justify-content-center">
                            <form onSubmit={submitAccountUpdate}>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-auto" style={{width: "50%"}}>
                                        <div className="form-floating mb-3">
                                            <input type="text" value={newTicket.ticketNumber} className="form-control" id="ticketNum"
                                                   placeholder="name@example.com" disabled />
                                            <label className="text-black" htmlFor="ticketNum">Ticket Number</label>
                                        </div>
                                    </div>
                                    <div className="col-auto" style={{width: "50%"}}>
                                        <div className="form-floating">
                                            <select style={{width: "100%"}} name="type" value={newTicket.type} className="form-select text-black" id="type"
                                                    aria-label="Ticket Type" onChange={handleChange}>
                                                <option>Select a Status</option>
                                                <option value="Extension">Extension</option>
                                                <option value="Trunk">Trunk</option>
                                                <option value="Conference Bridge">Conference</option>
                                                <option value="RF Link">Allstar Link</option>
                                                <option value="General Inquiry">Inquiry</option>
                                                <option value="Report a Problem">Problem</option>
                                                <option value="Account Activation">Account Activation</option>
                                            </select>
                                            <label htmlFor="floatingSelect" className="text-black">Ticket Type</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-auto" style={{width: "50%"}}>
                                        <div className="form-floating mb-3">
                                            <input type="text" value={newTicket.requestor.username} className="form-control" id="userName"
                                                   placeholder="name@example.com" disabled />
                                            <label className="text-black" htmlFor="userName">Callsign</label>
                                        </div>
                                    </div>
                                    <div className="col-auto" style={{width: "50%"}}>
                                        <div className="form-floating mb-3">
                                            <input type="text" value={newTicket.requestor.email} className="form-control" id="email"
                                                   placeholder="name@example.com" disabled />
                                            <label className="text-black" htmlFor="email">Email</label>
                                        </div>
                                    </div>
                                </div>
                                {newTicket.type === "Account Activation" && (
                                    <div>
                                        <div className="belowNav">
                                            <object aria-label="License Copy" data={Buffer.from(newTicket.requestor.licenseCopy.data)} width="100%" height="450"></object>
                                        </div>
                                        <div className="input-group shadow-sm mt-2">
                                            <label className='input-group-text' htmlFor="accountActive">Account Active:</label>
                                            <select defaultValue={newTicket.requestor.accountActive} id="accountActive" name="accountActive"
                                                    className="form-select" onChange={handleChange}>
                                                <option value={false}>No</option>
                                                <option value={true}>Yes</option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                                {(newTicket.type === "Extension" || newTicket.type === "Trunk" ||
                                    newTicket.type === "Conference Bridge" || newTicket.type === "RF Link") ? (
                                    <>
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-auto" style={{width: "50%"}}>
                                                <div className="form-floating mb-3">
                                                    <input type="text" value={newTicket.extension} name="extension"
                                                           className="form-control" id="extension" onChange={handleChange}/>
                                                    <label className="text-black" htmlFor="extension">Extension</label>
                                                </div>
                                            </div>
                                            <div className="col-auto" style={{width: "50%"}}>
                                                <div className="form-floating mb-3">
                                                    <input type="text" value={newTicket.password} name="password" className="form-control"
                                                           id="password" onChange={handleChange} />
                                                    <label className="text-black" htmlFor="password">{(newTicket.type === "Conference Bridge" && 
                                                    newTicket.conferenceType === "Private") ? ('Admin PIN') : ('PIN')}</label>
                                                </div>
                                            </div>
                                            {(newTicket.type === "Conference Bridge" && newTicket.conferenceType === "Private") && (
                                                <>
                                                <div className="col-auto" style={{width: "50%"}}>
                                                    <div className="form-floating mb-3">
                                                        <input type="text" value={newTicket.bridgePin} name="bridgePin"
                                                               className="form-control" id="bridgePin" onChange={handleChange} />
                                                        <label className="text-black" htmlFor="bridgePin">Conference PIN</label>
                                                    </div>
                                                </div>
                                                <div className="col-auto" style={{width: "50%"}}>
                                                    <div className="form-floating mb-3">
                                                        <input type="text" value={newTicket.bridgeName} name="bridgeName"
                                                            className="form-control" id="bridgeName" onChange={handleChange} />
                                                        <label className="text-black" htmlFor="bridgeName">Bridge Name</label>
                                                    </div>
                                                </div>
                                                </>
                                            )}
                                            {(newTicket.type === "RF Link") && (
                                                <div className="col-auto" style={{width: "100%"}}>
                                                    <div className="form-floating mb-3">
                                                        <input type="text" value={newTicket.text} name="text"
                                                               className="form-control" id="connectString" onChange={handleChange} />
                                                        <label className="text-black" htmlFor="bridgePin">IAX String</label>
                                                    </div>
                                                    <div className="form-floating mb-3">
                                                        <input type="text" value={newTicket.bridgeName} name="bridgeName"
                                                               className="form-control" id="bridgeName" onChange={handleChange} />
                                                        <label className="text-black" htmlFor="bridgePin">Link Name</label>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <Row>
                                        <Col className='text-black'>
                                            <FloatingLabel
                                                controlId="floatingTextarea"
                                                label="Comments"
                                                className="mb-3 text-black"
                                            >
                                                <Form.Control as="textarea" style={{height: '180px'}} disabled={true} value={newTicket.text} onChange={null} />
                                            </FloatingLabel>
                                        </Col>
                                    </Row>
                                )}
                                <div className="row d-flex justify-content-center">
                                    <div className="col-auto" style={{width: "50%"}}>
                                        <div className="form-floating">
                                            <select name="status" value={newTicket.status} className="form-select text-black" id="floatingSelect"
                                                    aria-label="Floating label select example" onChange={handleChange}>
                                                <option>Select a Status</option>
                                                <option value="Submitted">Submitted</option>
                                                {newTicket.type !== "Account Activation" ? (
                                                    <option value="In Progress">In Progress</option>
                                                ) : (
                                                    <option value="In Progress" disabled>In Progress</option>
                                                )}
                                                <option value="Closed">Closed</option>
                                            </select>
                                            <label htmlFor="floatingSelect" className="text-black">Ticket Status</label>
                                        </div>
                                    </div>
                                    <div className="col-auto" style={{width: "50%"}}>
                                        <div className="form-floating">
                                            <select defaultValue={newTicket.agent} className="form-select text-black" id="agent" name="agent"
                                                    aria-label="Agent Select" onChange={handleChange}>
                                                {users.map(u => (
                                                    u.isAdmin > 0 && (
                                                        <option key={u._id} value={u._id}>{u.username}</option>
                                                    )
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelect" className="text-black">Assigned</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center mt-3">
                                    <div className="col-auto"  style={{width: "100%"}}>
                                        <div className="form-floating mb-3">
                                            <input type="text" value={newTicket.requestor.userIp} name="bridgeName"
                                                    className="form-control" id="bridgeName" onChange={handleChange} />
                                            <label className="text-black" htmlFor="bridgePin">User IP</label>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-block mt-2 mb-2" type="submit">Update Ticket</button>
                            </form>
                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="text-black mt-3" style={{height: '100%'}}>
                    <Card>
                        <Card.Header>
                            <h4>Notes</h4>
                        </Card.Header>
                        <Card.Body className='noteCardBody'>
                            <Row>
                                <Col>
                                    {tickets.map(tick => (
                                        tick._id === newTicket._id && (
                                            tick.notes.map(n => (
                                                <Card key={n._id} className='mb-1'>
                                                    <Card.Header>
                                                        {users.map(u => (
                                                            n.user.includes(u._id) && (
                                                                u.username
                                                            )
                                                        ))}
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <p className='text-start'>{n.text}</p>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <p className='text-end'><Moment format="YYYY/MM/DD hh:mm:ss">{n.dateTime}</Moment></p>
                                                    </Card.Footer>
                                                </Card>
                                            ))
                                        )
                                    ))}
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            {sendingNote && (
                                <Row className="text-center">
                                    <Col>
                                        <Alert className="small-alert">
                                            Sending Message...
                                        </Alert>
                                    </Col>
                                </Row> 
                            )}
                            <Row className="mt-1">
                                <form onSubmit={handleSubmitNote}>
                                    <Col className="d-flex justify-content-start" style={{minWidth: '300px'}}>
                                        <textarea className="form-control" cols="45" onChange={handleSetNote} value={note.text} name='text'></textarea>
                                        <button type="submit" className="btn btn-sm btn-primary">Send</button>
                                    </Col>
                                </form>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default WorkTicket;