import {Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import k4avgLogo from '../../Images/k4avgLogo.png';

const Footer = ({user}) => {
    return(
        <>
            <div className="footContainer">
                <div className='footer container-fluid'>
                    <Row>
                        <h3 className='mt-3'>Hams Over IP</h3>
                    </Row>
                    <hr />
                    <Row className=''>
                        <Col>
                            <Row>
                                <Link className='footerLink' to={`/`}>Home</Link>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Link className='footerLink' to={`/phonebook`}>Phonebook</Link>
                            </Row>
                            <Row>
                                <Link className='footerLink' to={`/wiki`}>HOIP Wiki</Link>
                            </Row>
                        </Col>
                        <Col className='mt-3'>
                            <img className='adLogo' src={k4avgLogo} alt={'K4AVG Web Applications'} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <p>© 2022 Hams Over IP</p>
                    </Row>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    user: state.auth
})

export default connect(mapStateToProps, null)(Footer);