import api from "../utils/api";
import { LOAD_USERS } from "../actions/types";
import { setAlert } from "./alert";

export const setUsers = users => ({
	type: LOAD_USERS,
	users
});

export const fetchUsers = () => async dispatch => {
	try {
        let res = await api.get(`/phonebook`)
        dispatch({
            type: LOAD_USERS,
            payload: res.data
          })
    } catch (err) {
        console.log(err)
        console.log(err);
        dispatch(setAlert(err.response.data.error.message, 'danger'));
    }
}

export const passwordReset = (payload) => async dispatch => {
    try {
        let res = await api.post(`/auth/forgot`, payload)
        if(res.status === 200){
            dispatch(setAlert('A reset link has been sent to the email on file!', 'primary'));
        } else {
            dispatch(setAlert('Something went wrong, please try again', 'danger'));
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
}

export const passwordSubmit = (payload) => async dispatch => {
    try {
        let res = await api.post(`/auth/reset`, payload)
        if(res.status === 200){
            dispatch(setAlert('Your password has been reset!', 'primary'));
        } else {
            dispatch(setAlert('Something went wrong, please try again', 'danger'));
        }
    } catch (err) {
        dispatch(setAlert(err.message, 'danger'));
    }
}

