import React, {useState} from "react";
import { connect } from 'react-redux';
import PhonebookEntry from './PhonebookEntry'
import { Table, Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import Spinner from "../layout/Spinner";
import {setAlert} from "../../actions/alert";


const Admin = ({auth: {loading, user, isAdmin}, extensions, bookSearch, page, users}) => {

    const [ sortField, setSortField ] = useState('extension');

    return(
        loading ? (
            <Spinner />
        ) : (
            <div className="belowNav">
                <Row className="text-start" >
                    <Table className="overflow shadow" size='sm' responsive='sm' variant='dark' striped bordered hover>
                        <thead className='shadow'>
                        { page === 'Conference' ? (
                            <tr>
                                <th>Extension</th>
                                <th>Description</th>
                                <th>Active Users</th>
                                <th>Room</th>
                            </tr>
                        ) : page === 'RFLink' ? (
                            <tr>
                                <th>Owner Callsign</th>
                                <th>Link Name</th>
                                <th>Allstar Node</th>
                                <th>Extension</th>
                            </tr>
                        ) : page === 'Audio' ? (
                            <tr>
                                <th>Owner Callsign</th>
                                <th>Name</th>
                                <th>Extension</th>
                            </tr>
                        ) : (
                            <tr>
                                <th onClick={() => setSortField('callsign')}>
                                    Callsign
                                    { sortField === 'callsign' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('name')}>
                                    Name
                                    { sortField === 'name' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('extension')}>
                                    Extension
                                    { sortField === 'extension' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('type')}>
                                    Type
                                    { sortField === 'type' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('status')} className='text-center'>
                                    BLF
                                    { sortField === 'status' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('dmrId')}>
                                    DMR ID
                                    { sortField === 'dmrId' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('city')}>
                                    City
                                    { sortField === 'city' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('state')}>
                                    State
                                    { sortField === 'state' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('country')}>
                                    Country
                                    { sortField === 'country' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                            </tr>
                        )}
                        </thead>
                        <tbody>
                        {extensions.map(e => (
                            <PhonebookEntry
                                key={e._id}
                                dmrId={e.dmrId}
                                username={e.callsign}
                                firstName={e.name}
                                city={e.city}
                                state={e.state}
                                country={e.country}
                                extension={e.extension}
                                description={e.description}
                                type={e.type}
                                userCount={e.userCount}
                                connectedUsers={e.connectedUsers}
                                allstarNode={e.allstarNode}
                                blf={e.status}
                                talking={e.talking}
                                currentUser={user}
                                isAdmin={isAdmin}
                                page={page}
                                user={users.filter(user => user.username.toLowerCase() === e.callsign.toLowerCase())}
                            />

                            )
                        )}
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    <p className='text-end'>* Experimental</p>
                </Row>
            </div>
        )
    )
}

Admin.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { setAlert })(Admin);