import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {io} from 'socket.io-client';

const AdminSettings = ({ setBookState, user }) => {

    const ENDPOINT = process.env.REACT_APP_SOCKET_URI;

    const [newUser, setNewUser] = useState({
        _id: user._id,
        dmrId: user.dmrId,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        city: user.city,
        state: user.state,
        country: user.country,
        email: user.email,
        isAdmin: user.isAdmin,
        accountActive: user.accountActive,
        userIp: user.userIp,
        newPassword: ""
        // ADD PASSWORD RESET
    });

    const handleChange = e => {
        setNewUser({...newUser, [e.target.name]: e.target.value});
    };

    const handleSubmit = e => {
        let socket = io(ENDPOINT);
        e.preventDefault();
        setBookState({adminSettings: false}); 
        socket.emit('send user update', newUser, {transports: ["websocket"]});
    }

    const { dmrId, username, firstName, lastName, city, state, country, email, isAdmin, accountActive, userIp, newPassword } = newUser;

        return(
            <div className="belowNav d-flex justify-content-center">
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <h4>
                                {username}
                            </h4>
                            <h4>
                                Account Settings
                            </h4>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mt-1">
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="username">Callsign: &nbsp;&nbsp;</label>
                                <input className="form-control" id="username" name="username" disabled onChange={handleChange} value={username} type="username" />
                            </div>
                        </Col>
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="isAdmin">DMR ID: &nbsp;&nbsp;</label>
                                <input type="text" className="form-control" value={dmrId} disabled />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="firstName">First Name:</label>
                                <input className="form-control" id="firstName" name="firstName" onChange={handleChange} value={firstName} type="text" />
                            </div>
                        </Col>
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="lastName">Last Name:</label>
                                <input className="form-control" id="lastName" name="lastName" onChange={handleChange} value={lastName} type="text" />
                            </div>
                        </Col>
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="email">Email:</label>
                                <input type="email" className="form-control" name="email" onChange={handleChange} value={email} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <div className="col-auto input-group shadow-sm">
                                <label className='input-group-text' htmlFor="city">City:</label>
                                <input className="form-control" id="city" name="city" onChange={handleChange} value={city} type="text" />
                            </div>
                        </Col>
                        <Col>
                            <div className="col-auto input-group shadow-sm">
                                <label className='input-group-text' htmlFor="state">State:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input className="form-control" id="state" name="state" onChange={handleChange} value={state} type="text" />
                            </div>
                        </Col>
                        <Col>
                            <div className="col-auto input-group shadow-sm">
                                <label className='input-group-text' htmlFor="country">Country:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                <input className="form-control" id="country" name="country" onChange={handleChange} value={country} type="text" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="isAdmin">Admin Level:</label>
                                <select id="isAdmin" name="isAdmin" className="form-select" onChange={handleChange}>
                                    <option selected={isAdmin === 0 ? (true):(false)} value={0}>None</option>
                                    <option selected={isAdmin === 1 ? (true):(false)} value={1}>Support</option>
                                    <option selected={isAdmin === 2 ? (true):(false)} value={2}>Admin</option>
                                    <option selected={isAdmin === 3 ? (true):(false)} value={3}>Super Admin</option>
                                </select>
                            </div>
                        </Col>
                        <Col>
                            <div className="input-group shadow-sm">
                                <label className='input-group-text' htmlFor="accountActive">Account Active:</label>
                                <select id="accountActive" value={accountActive} name="accountActive" className="form-select" onChange={handleChange}>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <div className="col-auto input-group shadow-sm">
                                <label className='input-group-text' htmlFor="userIp">User IP Address</label>
                                <input className="form-control" id="userIp" name="userIp" onChange={handleChange} value={userIp} type="text" disabled />
                            </div>
                        </Col>
                        <Col>
                            <div className="col-auto input-group shadow-sm">
                                <label className='input-group-text' htmlFor="newPassword">Password Change</label>
                                <input className="form-control" id="newPassword" name="newPassword" onChange={handleChange} value={newPassword} type="password" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <Button type="submit" style={{width: '100%'}}>Update {username}</Button>
                        </Col>
                    </Row>
                </form>
            </div>
        );
}

AdminSettings.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, null)(AdminSettings);
