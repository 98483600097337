/* eslint-disable import/no-anonymous-default-export */
import { LOAD_EXTENSIONS } from "../actions/types";

const DEFAULT_STATE = {
    extensions: null,
    loading: true
}

export default (state = DEFAULT_STATE, action) => {
    const { type, payload } = action
    switch(type){
        case LOAD_EXTENSIONS:
            return {
                extensions: [...payload],
                count: payload.count,
                loading: false,
    }
        default:
            return state;
    }
}