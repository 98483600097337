import React from "react";
import { Button } from "react-bootstrap";

const UserEntry = ({ username, dmrId, firstName, lastName, accountActive, city, state, country, handleAdminSettings, isAdmin, userAdminLevel }) => {
  return(
        <tr>
            <td><a className='footerLink' rel="noreferrer" target='_blank' href={`https://www.qrz.com/db/${username}`} >{username}</a></td>
            <td>{lastName}, {firstName}</td>
            <td>{city}</td>
            <td>{state}</td>
            <td>{country}</td>
            <td>{dmrId}</td>
            <td>{ accountActive ? ('Verified') : ('Unverified')}</td>
            { isAdmin > 2 && (
                <>
                  <td>{userAdminLevel === 0 ? ("None") : userAdminLevel === 1 ? ("Support") : userAdminLevel === 2 ? ("Admin") : ("Super Admin") }</td>
                  <td><Button onClick={handleAdminSettings}>Admin Settings</Button></td>
                </>
            )}
        </tr>
  )
}

export default UserEntry;