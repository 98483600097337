import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import tickets from './tickets';
import users from './users';
import extensions from './extensions';

const rootReducer = combineReducers({
  alert,
  auth,
  tickets,
  users,
  extensions
});

export default rootReducer;