import React, {useState, useEffect, useReducer} from "react";
import {Row} from "react-bootstrap";
import PhonebookNav from "./PhonebookNav";
import Phonebook from "./Phonebook";
import BLF from "./BLF";
import Conference from "./Conference";
import {io} from "socket.io-client";
import Spinner from "../layout/Spinner";
import RFLink from "./RFLink";
import Audio from "./Audio";
import Admin from "./Admin";

const Directory = ({auth}) => {
    const ENDPOINT = process.env.REACT_APP_SOCKET_URI.toString();

    const [socketLoad, setSocketLoad] = useState(true);

    const [{ users }, userDispatch] = useReducer((state, action) => {
        if (action.type === 'LOAD_USERS') {
            const newUsers = [...action.data];
            newUsers.map(u => {
                const index = newUsers.findIndex(nu => nu._id === u._id);
                newUsers[index] = {_id: u._id, username: u.username, dmrId: u.dmrId, firstName: u.firstName, 
                    lastName: u.lastName, city: u.city, state: u.state, country: u.country};
            })
            state.users = newUsers;
            return state
        }
        return state
    }, { users: [] });

    const [{ extensions }, dispatch] = useReducer((state, action) => {
        if (action.type === 'NEW_EXTENSION') {
            state.extensions = action.data;
            return state
        }
        if(action.type === 'UPDATE_EXT'){
            const index = state.extensions.findIndex(ext => ext.extension === action.data.extension);
            const newArray = [...state.extensions];
            newArray[index] = action.data;
            return {
                ...state,
                extensions: newArray
            }
        }
        return state
    }, { extensions: [] });

    const handleFirstLoad = () => {
        try {
            let socket = io(ENDPOINT, {transports: ["websocket"]});
            let verification = {id: auth.auth._id, jwt: localStorage.getItem('token')}
            socket.emit('phonebook get', verification);
            socket.on('phonebook set', (data) => {
                dispatch({ type: 'NEW_EXTENSION', data });
                setSocketLoad(false);
            });
            // socket.emit ('user get', verification);
            // socket.on('user set', async function (data) {
            //     userDispatch({type: 'LOAD_USERS', data});
            // });
        } catch (error) {
            console.log(error);
        }
    }
    if(socketLoad) {
        handleFirstLoad();
    }

    const handleReload = e => {
        e.preventDefault();
        setSocketLoad(true);
    }

    useEffect(() => {
        let socket = io(ENDPOINT, {transports: ["websocket"]});
        socket.on("phonebook update", (data) => {
            dispatch({ type: 'UPDATE_EXT', data });
        });
        return () => {
            socket.disconnect();
        }
    }, [ENDPOINT, dispatch]);

    const [ page, setPage ] = useState("Extension");

    const handleList = e => {
        e.preventDefault();
        setPage(e.target.name);
    }

    const [search, setSearch] = useState('');

    const [ confPage, setConfPage ] = useState(true);

    const handleSearch = e => {
        e.preventDefault();
        setSearch(e.target.value);
    }

    return(
        socketLoad ? (
            <Spinner />
        ) : (
            <div className="belowNav">
                <PhonebookNav
                    className='PbNav'
                    page={page}
                    // user={auth.auth.user}
                    handleList={handleList.bind(this)}
                    handleSearch={handleSearch}
                    search={search}
                    confPage={confPage}
                    setConfPage={setConfPage}
                />
                <Row>
                    {page === "Extension" ? (
                        <Phonebook
                            page={page}
                            extensions={extensions.filter(e => (e.type === 'Extension' || e.type === 'Trunk'))}
                            bookSearch={search}
                            users={users}
                            handleReload={handleReload}
                        />
                    ) : page === 'Conference' ? (
                        <Conference
                            page={page}
                            conferences={[...extensions.filter(e => e.type === 'Conference')]}
                            users={users}
                            confPage={confPage}
                        />
                    ) : page === 'RFLink' ? (
                        <RFLink
                            page={page}
                            RFLinks={[...extensions.filter(e => e.type === 'RFLink')]}
                            users={users}
                        />
                    ) : page === 'Audio' ? (
                        <Audio
                            page={page}
                            extensions={[...extensions.filter(e => e.type === 'Audio')]}
                            users={users}
                        />
                    ) : page === 'Admin' ? (
                        <Admin
                            page={page}
                            extensions={[...extensions.filter(e => e.type === "Admin")]}
                            users={users}
                        />
                    ) : page === 'BLF' && (
                        <BLF
                            page={page}
                            extensions={extensions}
                            users={users}
                        />
                    )}
                </Row>
            </div>
        )
    )
}

export default Directory;