import React, {useState} from "react";
import { connect } from 'react-redux';
import PhonebookEntry from './PhonebookEntry'
import { Table, Row } from "react-bootstrap";
import PropTypes from 'prop-types';
import Spinner from "../layout/Spinner";
import {setAlert} from "../../actions/alert";


const Phonebook = ({auth: {loading, user, isAdmin}, users, extensions, bookSearch, page, handleReload}) => {

    const [ sortField, setSortField ] = useState('extension');
    return(
        loading ? (
            <Spinner />
        ) : (
            <div className="belowNav">
                <Row className="text-start" >
                    <Table responsive="sm" className="overflow shadow" size='sm' variant='dark' striped bordered hover>
                        <thead className='shadow'>
                        { page === 'Conference' ? (
                            <tr>
                                <th>Extension</th>
                                <th>Description</th>
                                <th>Active Users</th>
                                <th>Room</th>
                            </tr>
                        ) : page === 'RFLink' ? (
                            <tr>
                                <th>Owner Callsign</th>
                                <th>Link Name</th>
                                <th>Allstar Node</th>
                                <th>Extension</th>
                            </tr>
                        ) : page === 'Audio' ? (
                            <tr>
                                <th>Owner Callsign</th>
                                <th>Extension</th>
                                <th>Name</th>
                            </tr>
                        ) : (
                            <tr>
                                <th onClick={() => setSortField('callsign')}>
                                    Callsign
                                    { sortField === 'callsign' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('name')}>
                                    Name
                                    { sortField === 'name' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('extension')}>
                                    Extension
                                    { sortField === 'extension' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('type')}>
                                    Type
                                    { sortField === 'type' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('status')} className='text-center'>
                                    BLF
                                    { sortField === 'status' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('dmrId')}>
                                    DMR ID
                                    { sortField === 'dmrId' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('city')}>
                                    City
                                    { sortField === 'city' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('state')}>
                                    State
                                    { sortField === 'state' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                </th>
                                <th onClick={() => setSortField('country')} className="position-relative">
                                    Country
                                    { sortField === 'country' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-caret-down" viewBox="0 0 16 16">
                                            <path
                                                d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                        </svg>
                                    )}
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" onClick={handleReload}
                                             fill="currentColor" className="bi bi-arrow-clockwise position-absolute end-0" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                        </svg>
                                    </span>
                                </th>
                            </tr>
                        )}
                        </thead>
                        <tbody>
                        { bookSearch.length > 0 ? (
                            extensions
                                .sort((a,b) => {
                                    if (a[sortField] < b[sortField]) {
                                        return -1;
                                    }
                                    if (a[sortField] > b[sortField]) {
                                        return 1;
                                    }
                                    return 0;
                                }).map(e => (
                                        (page !== 'Conference' && e.callsign && e.callsign.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        e.extension.toLowerCase().includes(bookSearch.toLowerCase()) ||
                                        (page !== 'Conference' && e.country && e.country.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (page !== 'Conference' && e.city && e.city.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (page !== 'Conference' && e.state && e.state.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (page !== 'Conference' && e.dmrId && e.dmrId.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (page === 'Conference' && e.description && e.description.toLowerCase().includes(bookSearch.toLowerCase())) ||
                                        (page !== 'Conference' && e.callsign && e.name && e.name.toLowerCase().includes(bookSearch.toLowerCase()))
                                    ) && (
                                        <>
                                            <PhonebookEntry
                                                key={e._id}
                                                id={e._id}
                                                dmrId={e.dmrId} 
                                                username={e.callsign}
                                                firstName={e.name}
                                                city={e.city}
                                                state={e.state}
                                                country={e.country}
                                                extension={e}
                                                description={e.description}
                                                type={e.type}
                                                blf={e.status}
                                                connectedUsers={e.connectedUsers}
                                                userCount={e.userCount}
                                                allstarNode={e.allstarNode}
                                                talking={e.talking}
                                                currentUser={user}
                                                isAdmin={isAdmin}
                                                page={page}
                                                user={users.filter(user => user.username.toLowerCase() === e.callsign.toLowerCase())}
                                            />
                                        </>


                            ))
                        ) : (
                            !extensions.loading && (
                                extensions
                                    .sort((a,b) => {
                                        if (a[sortField] < b[sortField]) {
                                            return -1;
                                        }
                                        if (a[sortField] > b[sortField]) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map(e => (
                                        <PhonebookEntry
                                            key={e._id}
                                            dmrId={e.dmrId}
                                            username={e.callsign}
                                            firstName={e.name}
                                            city={e.city}
                                            state={e.state}
                                            country={e.country}
                                            extension={e}
                                            description={e.description}
                                            type={e.type}
                                            userCount={e.userCount}
                                            connectedUsers={e.connectedUsers}
                                            allstarNode={e.allstarNode}
                                            blf={e.status}
                                            talking={e.talking}
                                            currentUser={user}
                                            isAdmin={isAdmin}
                                            page={page}
                                            user={users.filter(user => user.username.toLowerCase() === e.callsign.toLowerCase())}
                                        />
                                    )
                                )
                            )
                        )}
                        </tbody>
                    </Table>
                </Row>
                <Row>
                    <p className='text-end'>* Experimental</p>
                </Row>
            </div>
        )
    )
}

Phonebook.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { setAlert })(Phonebook);