/* eslint-disable import/no-anonymous-default-export */
import {LOAD_USERS} from "../actions/types";

export default (state = [], action) => {
    const { type, payload } = action;
    switch(type){
        case LOAD_USERS:
            return [...payload.users]
        default:
            return state;
    }
}