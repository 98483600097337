import React, {Fragment, useEffect, useState} from "react";
import { connect } from 'react-redux';
import { InputGroup, Card, Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import {io} from "socket.io-client";
import axios from "axios";
import { RfLinkPopup } from "./Popups";

const RequestForm = ({ user, setRequestNew }) => {

  const ENDPOINT = process.env.REACT_APP_SOCKET_URI;

  let [ formData, setFormData ] = useState({
    region: "",
    type: "",
    lat: "",
    lon: "",
    conferenceType: "",
    bridgeName: "",
    bridgePin: "",
    allstar: "",
    text: "",
    userId: user._id,
  });

  let [ clickedSubmit, setClickedSubmit ] = useState(false);
  

  const newTicket = async (data) => {
    let socket = io(ENDPOINT, {transports: ["websocket"]});
    socket.emit('new ticket', data);
  }

  const handleSubmit = e => {
    e.preventDefault();
    setClickedSubmit(true);
    newTicket(formData);
    setRequestNew(false);
  }

  const [ disclaimer, setDisclaimer ] = useState({
    show: false,
    type: ""
  });

  const handleChange = e => {
    if(e.target.name === "type" && e.target.value === "RF Link"){
      setDisclaimer({show: true, type: e.target.value});
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const { region, type, allstar, lat, lon, text, conferenceType, bridgeName, bridgePin } = formData
  
  return(
    <div className="d-flex justify-content-center">
      <Card style={{ width: '36rem' }}>
         <Card.Header>
           <div className="position-relative">
             <span className="text-center text-black">Request a service</span>
             <span className="position-absolute end-0" onClick={() => setRequestNew(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
             </span>
           </div>
         </Card.Header>
         <Card.Body>
           <form onSubmit={handleSubmit}>
             <InputGroup>
               <InputGroup.Text>Select your Region</InputGroup.Text>
               <select className="form-select" name="region" onChange={handleChange}>
                 <option value="">Pick an option...</option>
                 <option value="AP">Asia/Pacific</option>
                 <option value="EU">Europe</option>
                 <option value="US">United States</option>
               </select>
             </InputGroup>
             {region.length > 0 && (
              <InputGroup className="mt-2">
                <InputGroup.Text>Type of Request</InputGroup.Text>
                <select className="form-select" name="type" onChange={handleChange} required>
                  <option value="">Pick an option...</option>
                  <option value="Extension">New Line</option>
                  <option value="Trunk">New Trunk</option>
                  <option value="Conference Bridge">New Conference Bridge</option>
                  <option value="RF Link">New Allstar Link</option>
                  <option value="General Inquiry">General Inquiry</option>
                  <option value="Report a Problem">Report a Problem</option>
                </select>
              </InputGroup>
             )}
             { type === "RF Link" && (
              <Fragment>
                <InputGroup className="mt-2">
                  <InputGroup.Text>Allstar Node Number</InputGroup.Text>
                  <input type="number" className="form-control" value={allstar} name="allstar" onChange={handleChange} required />
                </InputGroup>
                <InputGroup className='mt-2'>
                  <InputGroup.Text>Allstar Link Name</InputGroup.Text>
                  <input type="text" className="form-control" value={bridgeName} name="bridgeName" onChange={handleChange} required />
                </InputGroup>
                <InputGroup className='mt-2'>
                  <InputGroup.Text>IAX String</InputGroup.Text>
                  <input type="text" className="form-control" value={text} name="text" onChange={handleChange} required />
                </InputGroup>
              </Fragment>
             )}
             { (type === "Extension" || type === "Trunk" || type === "RF Link") && (
              <InputGroup className="mt-2">
                <InputGroup.Text>Latitude</InputGroup.Text>
                <input type="number" className="form-control" value={lat} name="lat" onChange={handleChange} />
                <InputGroup.Text>Longitude</InputGroup.Text>
                <input type="number" className="form-control" value={lon} name="lon" onChange={handleChange} />
              </InputGroup>
             )}
             { type === "Conference Bridge" && (
              <InputGroup className="mt-2">
                <InputGroup.Text>Conference Bridge Type</InputGroup.Text>
                <select className="form-select" name="conferenceType" onChange={handleChange} required>
                  <option value="">Pick an option...</option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>
              </InputGroup>
             )}
             { (type === "General Inquiry" || type === "Report a Problem") && (
              <InputGroup className="mt-2">
                <InputGroup.Text>How may we help?</InputGroup.Text>
                <textarea className="form-control" value={text} name="text" onChange={handleChange} required />
              </InputGroup>
             )}
             { conferenceType.length > 0 && (
              <InputGroup className="mt-2">
                <InputGroup.Text>Conference Bridge Name</InputGroup.Text>
                <input type="text" className="form-control" value={bridgeName} name="bridgeName" onChange={handleChange} required />
              </InputGroup>
             )}
             { conferenceType === "Private" && (
              <InputGroup className="mt-2">
                <InputGroup.Text>Conference Bridge PIN</InputGroup.Text>
                <input type="number" className="form-control" minLength="4" maxLength="4" value={bridgePin} name="bridgePin" onChange={handleChange} required />
              </InputGroup>
             )}
             <Button type="submit" className="btn-block mt-2" disabled={clickedSubmit} >{!clickedSubmit ? ('Submit Request') : ('Submitted')}</Button>
           </form>
         </Card.Body>
     </Card>
     <RfLinkPopup 
      setRequestNew={setRequestNew}
      setDisclaimer={setDisclaimer}
      disclaimer={disclaimer}
     />
    </div>
  )
}

RequestForm.propTpyes = {
  createTicket: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state =>({
  user: state.auth.user
})

export default connect(mapStateToProps, null)(RequestForm);
