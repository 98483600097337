import axios from 'axios';
import store from '../store';
import { LOGOUT } from '../actions/types';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
      'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  res => res,
  err => {
      console.log(err);
    setTimeout(() => {
        if (err.response.status === 401) {
            store.dispatch({ type: LOGOUT });
        }
        return Promise.reject(err);
    })
  }
);

export default api;
