import React, {useEffect, useState} from "react";
import { Modal, Table, Card, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import InUse from '../../Images/inuse.png';
import Idle from '../../Images/idle.png';
import Ringing from '../../Images/ringing.gif';

const PhonebookEntry = ({ id, username, firstName, extension, blf, description,
                            connectedUsers, allstarNode, page, type }) => {

    const [ inConf, setInConf ] = useState(false);

    if(inConf){
        let talking = connectedUsers.reverse().map(({name, talking, _id}) => (
            talking === 'on' && (
                <Col key={_id}>
                    <Card>
                        <span>{name}</span>
                    </Card>
                </Col>
            )
        ))
        let connectedU = connectedUsers.map(cu => (
            <tr key={cu._id}>
                <td>{cu.name.split(' ')[0]}</td>
                <td>{cu.name.split(' ')[1]}</td>
                <td>{cu.extension}</td>
            </tr>
        ))
        return(
            <>
                <>
                    <Modal size='lg' className='text-black' show={inConf}>
                        <Modal.Header onClick={() => setInConf(false)} closeButton>
                            <Modal.Title>
                                Conference {extension.extension} {extension.description && (<span>| {extension.description}</span>)}
                            </Modal.Title>
                        </Modal.Header>
                        {
                            connectedU.length > 0 ? (
                                <>
                                    <Modal.Body>
                                        <Card>
                                            <Card.Header>
                                                <h4>Currently Talking</h4>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row className="d-flex justify-content-center">
                                                    {talking}
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Modal.Body>
                                    <hr/>
                                    <Modal.Body>
                                        <Table striped bordered hover variant="dark" size='sm'>
                                            <thead>
                                            <tr>
                                                <th>Call</th>
                                                <th>Name</th>
                                                <th>Extension</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {connectedU}
                                            </tbody>
                                        </Table>
                                    </Modal.Body>
                                </>
                            ) : (
                                <p className='mt-3'>
                                    There are currently no users in this conference
                                </p>
                            )
                        }
                    </Modal>
                </>
            </>
        )
    } else {
        return (
            (page === 'Conference' && !inConf) ? (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-1">Click for Detail</Tooltip>}
                >
                    <tr onClick={() => setInConf(true)}>
                        <td>{extension.extension}</td>
                        <td>{extension.description}</td>
                        <td>{connectedUsers.length}</td>
                    </tr>
                </OverlayTrigger>
            ) : page === 'RFLink' ? (
                <tr>
                    <td>
                        <a className='footerLink' target='_blank' rel="noreferrer"
                           href={`https://www.qrz.com/db/${extension.callsign}`}>{extension.callsign}
                        </a>
                    </td>
                    <td>{extension.extension}</td>
                    <td>{extension.name.split(";")[0]}</td>
                    <td>{extension.name.split(";")[2]}</td>
                </tr>
            ) : page === 'Audio' ? (
                <tr>
                    <td>
                        <a className='footerLink' target='_blank' rel="noreferrer"
                           href={`https://www.qrz.com/db/${extension.callsign.split(";")[0]}`}>{extension.callsign.split(";")[0]}
                        </a>
                    </td>
                    <td>{extension.extension}</td>
                    <td>{extension.callsign.split(";")[1] + " " + extension.name}</td>
                    <td>{}</td>
                </tr>
            ) : (page === 'BLF' && blf === 'InUse') ? (
                <tr key={id}>
                    <td><a className='footerLink' target='_blank' rel="noreferrer"
                           href={`https://www.qrz.com/db/${username}`}>{username}</a></td>
                    <td>{extension.name && (extension.name) }</td>
                    <td>{extension.extension}</td>
                    <td>{type}</td>
                    <td>
                        {
                            extension.extension.startsWith('1') ? (
                                'HOIP-US'
                            ) : extension.extension.startsWith('2') ? (
                                'HOIP-EU'
                            ) : extension.extension.startsWith('3') && (
                                'HOIP-AP'
                            )
                        }
                    </td>
                </tr>
            ) : (
                <tr key={id}>
                    <td><a className='footerLink' target='_blank' rel="noreferrer"
                           href={`https://www.qrz.com/db/${extension.callsign}`}>{extension.callsign}</a></td>
                    <td>
                        {extension.name && (extension.name)}
                    </td>
                    <td>{extension.extension}</td>
                    <td>{type}</td>
                    <td>
                        <div className='d-flex justify-content-center'>
                            {blf === 'InUse' ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip id="button-tooltip-1">In Use</Tooltip>}
                                >
                                    <img className='blf' rel="noopener" src={InUse} alt={'1'} />
                                </OverlayTrigger>
                            ) : blf === 'Ringing' ? (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip id="button-tooltip-2">Ringing</Tooltip>}
                                >
                                    <img className='blf' rel="noopener" src={Ringing} alt={'2'} />
                                </OverlayTrigger>
                            ) : (
                                <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip id="button-tooltip-3">Available</Tooltip>}
                                >
                                    <img className='blf' rel="noopener" src={Idle} alt={'3'} />
                                </OverlayTrigger>
                            )}
                        </div>

                    </td>
                    <td>
                        {extension.dmrId && (extension.dmrId)}
                    </td>
                    <td>
                        {extension.city && (extension.city)}
                    </td>
                    <td>
                        {extension.state && (extension.state)}
                    </td>
                    <td>
                        {extension.country && (extension.country) }
                    </td>
                </tr>
            )
        )
    }
}

export default PhonebookEntry;