import React, { useEffect, useState, useReducer } from "react";
import { connect } from 'react-redux';
import {Row, Col, Card, Button, InputGroup, Container} from "react-bootstrap";
import PropTypes from 'prop-types';
import RequestForm from "./RequestForm";
import {ActiveTicket, DashboardExtension} from './DashboardItems';
import { updateUser } from "../../actions/auth";
import { Buffer } from "buffer";
import {io} from "socket.io-client";
import Spinner from "../layout/Spinner";
import axios from 'axios';


const Dashboard = ({ auth: {user, isAuthenticated}, updateUser, setAlert, }) => {

    const ENDPOINT = process.env.REACT_APP_SOCKET_URI;

    const [loading, setLoading] = useState(true);

    const [{ extensions }, dispatch] = useReducer((state, action) => {
        if (action.type === 'NEW_EXTENSION') {
            state.extensions = action.data;
            return state
        }
        if(action.type === 'UPDATE_EXT'){
            const index = state.extensions.findIndex(ext => ext.extension === action.data.extension);
            const newArray = [...state.extensions];
            newArray[index] = action.data;
            return {
                ...state,
                extensions: newArray
            }
        }
        return state
    }, { extensions: [] });

    const [{ tickets }, dispatchTick] = useReducer((state, action) => {
        if (action.type === 'LOAD_TICKETS') {
            let newTickets = [];
            // eslint-disable-next-line array-callback-return
            action.data.map(t => {
                if(t.requestor._id === user._id && t.type !== "Account Activation"){
                    newTickets.push(t);
                }
            })
            state.tickets = newTickets;
            return state
        }
        if(action.type === 'UPDATE_TICKET'){
            const index = state.tickets.findIndex(ticket => (
                ticket.ticketNumber === action.data.ticket.ticketNumber
            ));
            const newArray = [...state.tickets];
            newArray[index] = action.data.ticket;
            return {
                ...state,
                tickets: newArray
            }
        }
        if(action.type === 'NEW_TICKET' && action.data.ticket.requestor._id === user._id){
            return {
                ...state,
                tickets: [...state.tickets.filter(
                    ticket => ticket.ticketNumber !== action.data.ticket.ticketNumber && 
                    ticket.requestor._id === user._id), action.data.ticket]
            }
        }
        return state
    }, { tickets: [] });

    const [socketLoad, setSocketLoad] = useState(true);

    const handleFirstLoad = async () => {
        let userIp = ""
        await axios.get(`https://api.ipify.org?format=json`)
        .then(res => {
            userIp = res.data.ip;
        })
        .catch(err => {
            userIp = "Ask user to disable popup blockers.";
        });
        let socket = io(ENDPOINT, {transports: ['websocket']});
        let verification = {id: user._id, jwt: localStorage.getItem('token'), userIp};
        socket.emit('phonebook get', verification);
        socket.on('phonebook set', async function (data) {
            dispatch({ type: 'NEW_EXTENSION', data });
        });
        socket.emit('ticket get', verification);
        socket.on('ticket set', async function (data) {
            dispatchTick({ type: 'LOAD_TICKETS', data });
            setSocketLoad(false);
            setLoading(false);
        });
    }

    useEffect(() => {
        if(socketLoad && user.accountActive){
            handleFirstLoad();
        } else {
            setSocketLoad(false);
            setLoading(false);
        }
        let socket = io(ENDPOINT, {transports: ['websocket']});
        socket.on("phonebook update", (data) => {
            dispatch({ type: 'UPDATE_EXT', data })
        });
        socket.on("send ticket", (data) => {
            if(data.type === "update"){
                dispatchTick({ type: 'UPDATE_TICKET', data });
            }
            if(data.type === "new"){
                dispatchTick({ type: 'NEW_TICKET', data });
            }
        });

        return () => {
            socket.disconnect();
        }
    }, [ENDPOINT, dispatchTick])

    const [file, setFile] = useState([]);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        city: '',
        state: '',
        country: '',
    })
    const [ requestNew, setRequestNew ] = useState(false);

    if(isAuthenticated && !socketLoad){
        const fileToBase64 = (file, cb) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = function () {
              cb(null, reader.result)
            }
            reader.onerror = function (error) {
              cb(error, null)
            }
        }
        const handleUpload = e => {
            if (e.target.files < 1 || !e.target.validity.valid) {
                return
              }
              fileToBase64(e.target.files[0], (err, result) => {
                if (result) {
                  setFile(result)
                }
              })
        }

        const uploadLicenseCopy = async e => {
            e.preventDefault();
            let socket = io(ENDPOINT, {transports: ['websocket']});
            if(file.length > 0){
                await updateUser({ userId: user._id, licenseCopy: file, firstName: formData.firstName,
                    lastName: formData.lastName, city: formData.city, state: formData.state, country: formData.country
                })
                socket.emit('new ticket', {userId: user._id, type: 'Account Activation'});
                window.location = '/dashboard';
            } else {
                setAlert('Please attach a PDF copy of your license and try again.', 'danger')
            }
        }

        const handleChange = e => {
            e.preventDefault();
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }

        const { firstName, lastName, city, state, country } = formData

        return(
            user.accountActive ? (
            <Row className='mb-5 pb-5 container-fluid'>
                { requestNew && (
                    <Row className="d-flex justify-content-center mt-3">
                        <Col>
                            <RequestForm
                                className="shadow"
                                user={user}
                                setRequestNew={setRequestNew}
                            />
                        </Col>
                    </Row>
                ) }
                { !requestNew && (
                    <>
                        <Row>
                            <Col className="d-flex justify-content-center mt-3">
                                <Card className="extensionCards shadow" style={{ width: '20rem' }}>
                                    <Card.Header>
                                        <Card.Title className="text-black">Request a Service</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            Request a new Line, Trunk, Allstar Link, Conference Bridge, report a problem, or make a general inquiry.
                                        </Card.Text>
                                        {/*<a className="btn btn-primary" target="_blank" rel="noreferrer" href="https://helpdesk.hamsoverip.com/osticket/">Make a Request</a>*/}
                                        <Button onClick={setRequestNew}>Make a Request</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {tickets.map(t => (
                                t.status !== 'Closed' && (
                                    <ActiveTicket
                                        t={t}
                                        user={user}
                                        ENDPOINT={ENDPOINT}
                                    />
                                )
                            ))}
                        </Row>
                        {(!extensions.loading && extensions.length > 0) && (
                            <>
                                <hr className='mt-5'/>
                                <Row>
                                {extensions.map(e => (
                                    (e.callsign && e.callsign.toUpperCase() === user.username.toUpperCase()) && (
                                        <Col>
                                            <DashboardExtension
                                                key={e._id}
                                                type={e.type}
                                                extension={e.extension}
                                                status={e.status}
                                                user={user}
                                            />
                                        </Col>
                                    )
                                ))}
                                </Row>
                            </>
                        )}
                    </>
                )}
            </Row>
            ) : (!user.accountActive) && (
                <form onSubmit={uploadLicenseCopy}>
                    { !user.licenseCopy ? (
                        <Col className="d-flex justify-content-center mt-3">
                            <Card className="extensionCards shadow" style={{}}>
                                <Card.Body>
                                    <Card.Title className="text-black">Upload your License</Card.Title>
                                    <Card.Text>
                                        It seems there was an information mismatch with RadioID.net.
                                    </Card.Text>
                                    <Card.Text>
                                        Please fill out the form below and upload a PDF copy of your license for manual review.
                                    </Card.Text>
                                    <div className="mb-3">
                                        <InputGroup className="mt-2">
                                            <InputGroup.Text>First Name</InputGroup.Text>
                                            <input type="text" className="form-control" name="firstName" value={firstName} onChange={handleChange} required />
                                            <InputGroup.Text>Last Name</InputGroup.Text>
                                            <input type="text" className="form-control" name="lastName" value={lastName} onChange={handleChange} required />
                                        </InputGroup>
                                        <InputGroup className="mt-2">
                                            <InputGroup.Text>City</InputGroup.Text>
                                            <input type="text" className="form-control" name="city" value={city} onChange={handleChange} required />
                                            <InputGroup.Text>State</InputGroup.Text>
                                            <input type="text" className="form-control" name="state" value={state} onChange={handleChange} required />
                                            <InputGroup.Text>Country</InputGroup.Text>
                                            <input type="text" className="form-control" name="country" value={country} onChange={handleChange} required />
                                        </InputGroup>
                                        <InputGroup className="mt-2">
                                            <input className="form-control" onChange={handleUpload} id="formFileSm" type="file" />
                                        </InputGroup>
                                    </div>
                                    <Button type="submit">Upload</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ) : (!loading) && (
                        <Container>
                            <Row className="mb-5 pb-5 mt-5">
                                <Col className="d-flex justify-content-center">
                                    <Card className="extensionCards shadow" style={{ width: '50vw', height: "80vh" }}>
                                        <Card.Body>
                                            <h3 className="text-white">License Uploaded</h3>
                                            <Card.Text>
                                                <p>Your account is in review.</p>
                                            </Card.Text>
                                            <div className="mb-3">
                                                <Row>
                                                    <Col>
                                                        <object aria-label="License Copy" data={Buffer.from(user.licenseCopy.data)} height="600px" width="100%"></object>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </form>
            )
        )
    } else {
        return (
            <Spinner />
        )
    }
            
}

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
  }

const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, {updateUser})(Dashboard);