/* eslint-disable import/no-anonymous-default-export */
import { CREATE_TICKET, LOAD_TICKETS, UPDATE_TICKET } from "../actions/types";

const INITIAL_STATE = {
    tickets: null,
    loading: true
}

function ticketReducer(state = INITIAL_STATE, action) {
    const { type, payload } = action;
  
    switch(type){
        case CREATE_TICKET:
            return [...state.tickets, payload]
        case LOAD_TICKETS:
            return {
                ...state,
                tickets: [...payload],
                loading: false,
            }
        case UPDATE_TICKET:
            {
                const index = state.tickets.findIndex(ticket => ticket._id === payload.data._id);
                const newArray = [...state.tickets];
                newArray[index] = payload.data
                return {
                    ...state,
                    tickets: newArray,
                    loading: false
                }
            }
            
        default:
            return state;
    }
}
  
export default ticketReducer;