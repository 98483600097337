import React, {useState} from "react";
import {Container, Button} from "react-bootstrap";
import {passwordReset} from "../../actions/users";
import {connect} from "react-redux";
import PropTypes from "prop-types";


const Forgot = ({passwordReset}) => {

    const [ formData, setFormData ] = useState({
        email: ""
    });

    const [ clicked, setClicked ] = useState(false);

    const handleChange = e => {
        e.preventDefault();
        setFormData({
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        setClicked(true);
        passwordReset(formData);
    }

    if(!clicked){
        return(
            <Container className='mt-5 belowNav w-50'>
                <h1>Password Reset</h1>
                <div>
                    <p>Forgot your password?</p>
                    <p>Don't worry, just enter your email below for a reset link!</p>
                </div>
                <form className='form' onSubmit={handleSubmit}>
                    <div className="input-group shadow-sm mt-3">
                        <label className='input-group-text' htmlFor="email">Email Address:</label>
                        <input className="form-control" id="email" name="email" onChange={handleChange} value={formData.email} type="email" />
                    </div>
                    <Button type='submit' className='btn btn-primary mt-3'>Request Reset</Button>
                </form>
            </Container>
        )
    } else {
        return(
            <Container className='mt-5 belowNav w-50'>
                <h1>Password Reset</h1>
                <div className='mt-5'>
                    <h3>A reset link has been sent to your email!</h3>
                </div>
            </Container>
        )
    }
}

Forgot.propTypes = {
    passwordReset: PropTypes.func.isRequired,
}

export default connect(null, {passwordReset})(Forgot);