import React from "react";
import { Nav } from "react-bootstrap";

const TicketNav = ({handleSetPage, page}) => {

    return(
        <Nav variant="tabs" className='noBottomBorder'>
            <Nav.Item>
                <Nav.Link
                    onClick={handleSetPage}
                    active={page === 'Submitted'}
                    style={page !== 'Submitted' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                    name="Submitted"
                >New
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    onClick={handleSetPage}
                    active={page === 'My'}
                    style={page !== 'My' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                    name="My"
                >My Tickets
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    onClick={handleSetPage}
                    active={page === 'In Progress'}
                    style={page !== 'In Progress' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                    name="In Progress"
                >In Progress
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link
                    onClick={handleSetPage}
                    active={page === 'Closed'}
                    style={page !== 'Closed' ? {backgroundColor: '#242424', color: 'white'} : {backgroundColor: 'white', color: 'black'}}
                    name="Closed"
                >Closed
                </Nav.Link>
            </Nav.Item>
        </Nav>
    )
}

export default TicketNav;